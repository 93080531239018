import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadFirstAction,
  loadMoreAction,
  updateSearchParamsAction,
  updateSelectedImageAction,
} from 'modules/getTheLook/store/actions';
import { unauthorizedLimit } from 'constants/inspirationImages';
import errorToastr from 'libs/toastr/errorToastr';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import ListItem from 'modules/getTheLook/components/ListItem';
import useCancelToken from 'hooks/useCancelToken';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import Button from 'components/ui/Button/Button';
import { routesByName } from 'constants/routes';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import classes from './GetTheLook.module.scss';
import { getTheLookTabKeys } from './constants';

const ListView = ({ setStyleQuizModalOpen }) => {
  const dispatch = useDispatch();

  const {
    imageList: imageIdList,
    searchParams,
    loading,
    hasMore,
    activeTab,
  } = useSelector((state) => state.getTheLook);
  const library = useSelector((state) => state.inspirationImage.library);
  const currentUser = useSelector((state) => state.auth.user);
  const quizViewed = useSelector((state) => state.layout.quizViewed);

  const imageList = useMemo(() => imageIdList.map((id) => library[id]), [
    library,
    imageIdList,
  ]);

  const { pathname } = useLocation();
  const history = useHistory();
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  const [createCancelToken, isCancelled] = useCancelToken();

  useEffect(() => {
    dispatch(updateSelectedImageAction(null));
    dispatch(updateSearchParamsAction({ offset: 0 }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        try {
          await dispatch(loadFirstAction(createCancelToken));
        } catch (e) {
          if (!isCancelled(e)) {
            errorToastr('Error', e.message);
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, [JSON.stringify(searchParams)]);

  useEffect(() => {
    if (
      !loading &&
      (activeTab === getTheLookTabKeys.lookBoardView ||
        activeTab === getTheLookTabKeys.productStream)
    ) {
      dispatch(updateSelectedImageAction(null));
      dispatch(updateSearchParamsAction({ offset: 0 }));
    }
  }, [activeTab]);

  const handleLoadMore = useCallback(async () => {
    if (!currentUser && searchParams.offset >= unauthorizedLimit) return;
    try {
      dispatch(loadMoreAction());
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [dispatch, currentUser, searchParams]);

  const handleImgModalOpen = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleRetakeQuiz = useCallback(() => {
    if (!quizViewed) history.push(routesByName.styleQuiz);
    setStyleQuizModalOpen(true);
  }, [history, quizViewed, setStyleQuizModalOpen]);

  const handleRegisterClick = useCallback(() => {
    history.push(
      `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`,
      { title: 'REGISTER NOW' }
    );
  }, [history, pathname]);

  return (
    <>
      <CustomInfiniteScroll
        isLoading={loading}
        hasMore={hasMore}
        loadMore={handleLoadMore}
        initialLoad={false}
        threshold={500}
      >
        {imageList.length ? (
          <div
            className={matchesMediaQuery ? 'p-2' : 'pt-4'}
            style={{
              paddingTop: activeTab === getTheLookTabKeys.productStream && 100,
            }}
          >
            {imageList.map((image) => (
              <ListItem
                key={image.id}
                image={image}
                onOpenImgModal={handleImgModalOpen}
              />
            ))}
            {!currentUser && searchParams.offset >= unauthorizedLimit && (
              <div className="d-flex justify-content-center pb-3">
                <Button
                  size="md"
                  className={clsx('w-auto mx-auto', classes.btn)}
                  onClick={handleRegisterClick}
                >
                  Register Now to View All Images
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="d-flex w-100 pb-3 mt-3">
            {!matchesMediaQuery && <div className={classes.leftPanelWrapper} />}
            {loading ? (
              <div className="mt-2 mx-2">Room images are loading...</div>
            ) : (
              <>
                <div
                  className={clsx(
                    'flex-fill',
                    'd-flex',
                    'flex-column',
                    'justify-content-center',
                    'align-items-center',
                    classes.mainContainer,
                    classes.emptyContainer
                  )}
                >
                  <h2 className={classes.emptyTitle}>NO RESULTS AVAILABLE</h2>
                  <Button
                    onClick={handleRetakeQuiz}
                    variant="outlined"
                    className="d-block w-auto"
                    size="lg"
                  >
                    Retake the Style Quiz
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </CustomInfiniteScroll>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
    </>
  );
};

ListView.propTypes = {
  setStyleQuizModalOpen: PropTypes.func.isRequired,
};

export default memo(ListView);
