import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  getTheLookTabKeys,
  infoMenuConfig,
} from 'modules/getTheLook/constants';
import InfoMenu from 'modules/layout/SideNav/InfoMenu/InfoMenu';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/layout/SideNav/SideNav.module.scss';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveTabAction } from 'modules/getTheLook/store/actions';
import { SidebarTooltip } from './SidebarTooltip';
import { getTheLookAmbassadorsTabKeys } from '../ambassadorPage/config';
import { setAmbassadorsActiveTabAction } from '../ambassadorPage/store/actions';

const SideBar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [infoMenuOpen, setInfoMenuOpen] = useState(false);
  const currentUser = useSelector((state) => state.auth.user);
  const history = useHistory();

  const handleOpenInfoMenu = useCallback(() => {
    setInfoMenuOpen(true);
  }, []);

  const handleCloseInfoMenu = useCallback(() => {
    setInfoMenuOpen(false);
  }, []);

  const isUsersPage = useMemo(
    () => pathname.startsWith(routesByName.getTheLook.users.index),
    [pathname]
  );

  const handleTabClick = useCallback(() => {
    /*  if (!currentUser) {
      history.push(`?${routesByName.auth.key}=${routesByName.auth.signUp}`);
    } */
    dispatch(setAmbassadorsActiveTabAction(getTheLookAmbassadorsTabKeys.main));
  }, [dispatch]);

  const handleGetTheLookTabClick = useCallback(() => {
    dispatch(changeActiveTabAction(getTheLookTabKeys.galleryView));
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <SidebarTooltip text="Info">
        <div
          className={clsx(classes.navLink, {
            [classes.activeTabInfo]: infoMenuOpen,
          })}
          onClick={handleOpenInfoMenu}
        >
          <SpriteIcon name="info" />
        </div>
      </SidebarTooltip>
      <SidebarTooltip
        text="Inspiration Image Look Boards"
        onClick={handleGetTheLookTabClick}
      >
        <NavLink
          exact
          to={routesByName.getTheLook.index}
          className={classes.navLink}
          activeClassName={classes.activeTab}
        >
          <SpriteIcon name="living-room" size="lg" />
        </NavLink>
      </SidebarTooltip>
      <SidebarTooltip text="Ambassador Look Boards" onClick={handleTabClick}>
        <NavLink
          to={
            isUsersPage
              ? routesByName.getTheLook.users.index
              : routesByName.getTheLook.ambassadors.index
          }
          className={classes.navLink}
          activeClassName={classes.activeTab}
        >
          <SpriteIcon name="followers" size="lg" />
        </NavLink>
      </SidebarTooltip>
      <InfoMenu
        config={infoMenuConfig}
        isOpen={infoMenuOpen}
        onClose={handleCloseInfoMenu}
      />
    </div>
  );
};

export default SideBar;
