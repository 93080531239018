import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import authModalImg from 'assets/img/contact-page/banner.png';
import { routesByName } from 'constants/routes';
import SignUpContainer from 'modules/auth/signUp/SignUpContainer';
import SignInContainer from 'modules/auth/signIn/SignInContainer';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ButtonHowItWorks from 'components/ui/ButtonHowItWorks';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './AuthModal.module.scss';
import Button from '../../../../components/ui/Button/Button';

const cannotClose = [];
const AuthModal = ({ action, onClose, toggleHowItWorksModal }) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const { state, pathname } = useLocation();
  const history = useHistory();
  const currentUser = useSelector((dataState) => dataState.auth.user);
  const reopenTimeout = useRef(null);

  const handleOpenStyleQuizPage = useCallback(() => {
    history.push(routesByName.styleQuiz);
  }, [history]);

  const handleClose = useCallback(() => {
    if (state?.reopen) {
      reopenTimeout.current = setTimeout(() => {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`,
          { title: 'REGISTER NOW' }
        );
      }, state.timeout);
    }
    if (
      pathname === routesByName.faqs ||
      pathname === routesByName.requestTheLook.submit
    ) {
      onClose();
      return;
    }
    if (!cannotClose.includes(pathname) && !currentUser) {
      onClose();
    } else {
      history.push(routesByName.home);
    }
  }, [currentUser, history, onClose, pathname, state]);

  useEffect(() => {
    if (currentUser && reopenTimeout.current) {
      clearTimeout(reopenTimeout.current);
    }
  }, [currentUser]);

  useEffect(() => {
    clearTimeout(reopenTimeout.current);
  }, [pathname]);

  const handleHowItWorksModalOpen = useCallback(() => {
    handleClose();
    toggleHowItWorksModal(true);
  }, [handleClose, toggleHowItWorksModal]);

  const handleGoToHomepage = useCallback(() => {
    handleClose();
    history.push(routesByName.home);
  }, [handleClose, history]);

  return (
    <BasicModal
      open={Boolean(action)}
      onClose={handleClose}
      classes={{
        root: 'p-0',
        closeBtn: classes.closeBtn,
      }}
      fullWidth
      maxWidth="md"
      scroll="body"
      style={{ zIndex: 1299 }}
    >
      <div className="d-flex">
        <div className={classes.formContainer}>
          {action === routesByName.auth.signIn && (
            <SignInContainer onCloseModal={onClose} />
          )}
          {action === routesByName.auth.signUp && (
            <SignUpContainer onCloseModal={onClose} />
          )}
        </div>
        {!matchesMediaQuery && (
          <div className="w-50">
            <div className={classes.imgContainer}>
              <img src={authModalImg} alt="" />
              <div className={classes.banner}>
                <div className="position-relative text-center mb-1">
                  <span className={classes.bannerTitle}>Pin The Look</span>
                  <div className={classes.crossline} />
                </div>
                <p className={classes.bannerSubtitle}>
                  <span className="primary-color">DECORATING THE WORLD</span>{' '}
                  TOGETHER
                </p>
              </div>
            </div>
            {action === routesByName.auth.signUp && (
              <div className="d-flex flex-column align-items-center mt-3">
                <Button
                  className={classes.homepageBtn}
                  onClick={handleGoToHomepage}
                >
                  Homepage
                </Button>
                <ButtonHowItWorks
                  text="PIN THE LOOK"
                  onClick={handleHowItWorksModalOpen}
                />
                <button
                  type="button"
                  className={classes.quizBtn}
                  onClick={handleOpenStyleQuizPage}
                >
                  <div>
                    <div className={classes.subTitle}>
                      <div className={classes.line} />
                      <span className="px-1">Take the</span>
                      <div className={classes.line} />
                    </div>
                    <p className="text-sm">Style Quiz</p>
                  </div>

                  <SpriteIcon name="play-icon" size="lg" />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </BasicModal>
  );
};

AuthModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  toggleHowItWorksModal: PropTypes.func.isRequired,
  action: PropTypes.string,
};

AuthModal.defaultProps = {
  action: undefined,
};

export default AuthModal;
