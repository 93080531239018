import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  accountTypes,
  showProductsOptions,
  signUpFormFields,
} from 'modules/auth/signUp/constants';
import signUpValidator from 'modules/auth/signUp/signUpValidator';
import { signUpAction } from 'modules/auth/store/actions';
import { transformFormValuesToRegisterData } from 'modules/auth/signUp/transformers';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import SignUpComponent from 'modules/auth/signUp/SignUpComponent';
import { routesByName } from 'constants/routes';
import {
  toggleStyleQuizResultModalAction,
  toggleSuggestStyleQuizAction,
} from 'modules/layout/store/actions';
import mixAndMatchService from 'modules/mixAndMatch/mixAndMatchService';

const SignUpContainer = () => {
  const dispatch = useDispatch();
  const quizData = useSelector((state) => state.layout.styleQuizResult.data);

  const isStyleQuiz = useMemo(() => Boolean(quizData), [quizData]);

  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [formValues] = useState({
    [signUpFormFields.type]: accountTypes.personal,
    [signUpFormFields.showProducts]: showProductsOptions.all,
    [signUpFormFields.countryId]: 1,
  });

  const onSubmit = useCallback(
    async (values) => {
      const preparedUserData = transformFormValuesToRegisterData(values);
      setLoading(true);
      try {
        await dispatch(signUpAction(preparedUserData));
        successToastr('Success', 'You successfully registered');

        const selectedMixMatchIds = location.state?.ids;
        if (selectedMixMatchIds) {
          const path = location.pathname.split('/');
          const imageId = path[path.length - 1];
          try {
            await mixAndMatchService.saveMixAndMatch(
              imageId,
              selectedMixMatchIds
            );
            successToastr(
              'Success',
              'Your Mix & Match has been successfully saved'
            );
          } catch (e) {
            errorToastr('Error', e.message);
          }
        }
        if (!location.pathname === routesByName.requestTheLook.submit)
          if (location.pathname === routesByName.ambassador.index) {
            history.push(routesByName.ambassador.index);
          } else if (location.pathname === routesByName.styleQuiz) {
            history.push(routesByName.styleQuiz);
          } else {
            if (!isStyleQuiz) {
              dispatch(toggleSuggestStyleQuizAction(true));
            }
            history.push(routesByName.getTheLook.index);
            dispatch(toggleStyleQuizResultModalAction(true));
          }
      } catch (e) {
        const registeredEmailError =
          e.response.data?.validation?.email[0] &&
          'There is already an account with this email. Please login';
        errorToastr('Error', registeredEmailError || e.generalError);
        return {
          ...e.validationErrors,
        };
      } finally {
        setLoading(false);
      }
      return true;
    },
    [dispatch, history, isStyleQuiz, location.pathname, location.state]
  );

  return (
    <SignUpComponent
      initialValues={formValues}
      validateForm={signUpValidator}
      onSubmit={onSubmit}
      loading={loading}
      title={location.state?.title}
    />
  );
};

export default SignUpContainer;
