import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from 'modules/getTheLook/GetTheLook.module.scss';
import Button from 'components/ui/Button/Button';

const GTLSlider = ({
  sliderRef,
  slideLength,
  slidesToShow,
  activeSlideIndex,
  onSlidePrev,
  onSlideNext,
  onSlideChange,
  onViewAll,
  handleMoveToCTL,
  children,
}) => (
  <div className="position-relative">
    <IconButton
      onClick={onSlidePrev}
      disabled={activeSlideIndex === 0}
      className={classes.sliderControl}
      style={{ left: -64 }}
    >
      <SpriteIcon name="prev-icon" size="sm" />
    </IconButton>
    <Slider
      ref={sliderRef}
      arrows={false}
      infinite={false}
      slidesToShow={slidesToShow}
      slidesToScroll={1}
      afterChange={onSlideChange}
    >
      {children}
    </Slider>
    <div className={classes.sliderControl} style={{ right: -110 }}>
      <IconButton
        onClick={onSlideNext}
        disabled={activeSlideIndex >= slideLength - slidesToShow}
      >
        <SpriteIcon name="next-icon" size="sm" />
      </IconButton>
      {handleMoveToCTL && (
        <Button
          size="sm"
          variant="outlined"
          className="mt-2"
          style={{ borderRadius: 8 }}
          onClick={handleMoveToCTL}
        >
          <span>
            Curate <br /> this Look
          </span>
        </Button>
      )}
    </div>
    {onViewAll && (
      <div className={classes.viewAllBtn}>
        <Button variant="default" size="xs" onClick={onViewAll}>
          <span className="mr-1">View All</span>
          <SpriteIcon name="right-arrow" size="sm" />
        </Button>
      </div>
    )}
  </div>
);

GTLSlider.propTypes = {
  sliderRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  slideLength: PropTypes.number.isRequired,
  slidesToShow: PropTypes.number,
  activeSlideIndex: PropTypes.number.isRequired,
  onSlidePrev: PropTypes.func.isRequired,
  onSlideNext: PropTypes.func.isRequired,
  onSlideChange: PropTypes.func.isRequired,
  onViewAll: PropTypes.func,
  handleMoveToCTL: PropTypes.func,
  children: PropTypes.node.isRequired,
};

GTLSlider.defaultProps = {
  onViewAll: undefined,
  slidesToShow: 3,
  handleMoveToCTL: null,
};

export default memo(GTLSlider);
