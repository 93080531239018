import contemporary from 'assets/img/personas/contemporary.png';
import shabbyChic from 'assets/img/personas/shabby-chic.png';
import industrial from 'assets/img/personas/industrial.png';
import coastal from 'assets/img/personas/coastal.png';
import farmhouse from 'assets/img/personas/farmhouse.png';
import eclectic from 'assets/img/personas/eclectic.png';
import natural from 'assets/img/personas/natural.png';
import modern from 'assets/img/personas/modern.png';
import modernMallory from 'assets/img/personas/modern-mallory.png';
import rustic from 'assets/img/personas/rustic.png';
import french from 'assets/img/personas/french.png';
import transitional from 'assets/img/personas/transitional.png';
import traditional from 'assets/img/personas/traditional.png';
import scandinavianSam from 'assets/img/personas/scandinavian-sam.png';
import transitionalRustic from 'assets/img/personas/transitional-rustic.png';
import contemporaryGlam from 'assets/img/personas/contemporary-glam.png';
import naturalBoho from 'assets/img/personas/boho-brenda.png';
import organicJapandi from 'assets/img/personas/japandi-julie.png';
import coastalGrandMother from 'assets/img/personas/coastal-grandma.png';
import eclecticBoho from 'assets/img/personas/eclectic-boho.png';
import rusticLodge from 'assets/img/personas/rustic-lodge.png';
import tuscan from 'assets/img/personas/tuscan-teresa.png';
import vintageShabbyChic from 'assets/img/personas/vintage-shabby-chic.png';
import frenchClassic from 'assets/img/personas/french-classic.png';
import modernParisian from 'assets/img/personas/parisian-pauline.png';

const personasConfig = {
  1: traditional,
  2: contemporary,
  3: traditional,
  4: transitional,
  5: transitionalRustic,
  6: transitional,
  7: contemporary,
  8: contemporaryGlam,
  9: modern,
  10: modern,
  11: modern,
  12: modernMallory,
  13: scandinavianSam,
  14: farmhouse,
  16: farmhouse,
  18: industrial,
  19: eclectic,
  20: eclecticBoho,
  21: eclectic,
  22: industrial,
  24: industrial,
  25: eclectic,
  26: shabbyChic,
  28: vintageShabbyChic,
  29: farmhouse,
  30: rustic,
  32: rusticLodge,
  34: tuscan,
  35: natural,
  37: naturalBoho,
  38: organicJapandi,
  39: coastal,
  41: coastal,
  43: coastal,
  44: frenchClassic,
  45: french,
  47: modernParisian,
  48: rustic,
  50: coastalGrandMother,
};

const filterAndMapStyles = (styles) => {
  const uniquePersonas = new Set();

  styles.forEach((style) => {
    const persona = personasConfig[style] || null;
    if (persona) {
      uniquePersonas.add(persona);
    }
  });

  return Array.from(uniquePersonas);
};

const usePersonas = (subStyles) => {
  if (!Array.isArray(subStyles) || !subStyles.length) {
    return null;
  }

  return filterAndMapStyles(subStyles);
};

export default usePersonas;
