import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button/Button';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import SingleInspirationModal from 'modules/lookBoard/components/SingleInspirationModal/SingleInspirationModal';
import errorToastr from 'libs/toastr/errorToastr';
import classes from '../GetTheLook.module.scss';
import UserCard from './components/UserCard';
import NoLookBoardsModal from './components/NoLookBoardsModal/NoLookBoardsModal';
import LookBoardItem from '../components/LookBoardItem';
import { findUserAction, toggleLikeAction } from './store/actions';
import successToastr from '../../../libs/toastr/successToastr';

const UserDetails = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedUser: user, lookBoards: lookBoardsData } = useSelector(
    (state) => state.userPage
  );
  const { library: imagesData } = useSelector(
    (state) => state.inspirationImage
  );
  const [inspirationsModalOpen, setInspirationsModalOpen] = useState(false);
  const [tempImageId, setTempImageId] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);

  const handleToggleLikeLookBoard = useCallback(
    (lookBoardId, likeStatus) => {
      try {
        dispatch(toggleLikeAction(lookBoardId, likeStatus));
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch]
  );

  const handleCopyUrl = useCallback(() => {
    // TODO: link should open only when user shares his lookboards link
    // eslint-disable-next-line no-restricted-globals
    navigator.clipboard.writeText(location.href);
    successToastr('Success', 'Link successfully copied');
  }, []);

  const hasLookBoards = useMemo(() => {
    if (!user) return false;
    // eslint-disable-next-line no-restricted-syntax
    for (const id of user.lookBoards) {
      if (lookBoardsData[id]) return true;
    }
    return false;
  }, [lookBoardsData, user]);

  const handleInspirationsModalOpen = useCallback(
    async (imageId) => {
      const { result: image } = await inspirationImageService.getImageById(
        imageId
      );
      dispatch(updateIILibraryAction({ [imageId]: image }));
      setTempImageId(imageId);
      setInspirationsModalOpen(true);
    },
    [dispatch]
  );

  const handleInspirationsModalClose = useCallback(() => {
    setInspirationsModalOpen(false);
  }, []);

  useEffect(() => {
    if (!userId) history.back();
    if (!user) {
        setIsLoaded(false);
        dispatch(findUserAction(userId)).finally(() => {
        setIsLoaded(true);
      });
    }
  }, [dispatch, history, user, userId]);

  if (!isLoaded) {
    return 'Loading...';
  }

  return (
    <>
      <div
        style={{ marginTop: -110 }}
        className={clsx('d-flex position-relative mb-3 pt-2 pb-5')}
      >
        <div className={classes.leftPanelWrapper}>
          {user && <UserCard user={user} />}
        </div>
        <div className={classes.mainContainer}>
          {!hasLookBoards && <NoLookBoardsModal />}
          {hasLookBoards && (
            <>
              <div className="d-flex justify-content-end mb-2">
                <Button
                  variant="outlined"
                  inline
                  size="sm"
                  onClick={handleCopyUrl}
                >
                  <span className="px-2">Copy Page URL</span>
                </Button>
              </div>
              <div className="row mb-3">
                {user?.lookBoards.map(
                  (lookBoardId) =>
                    lookBoardsData[lookBoardId] && (
                      <div key={lookBoardId} className="col-4 px-1 pb-4">
                        <LookBoardItem
                          lookBoard={lookBoardsData[lookBoardId]}
                          products={lookBoardsData[lookBoardId].products}
                          user={user}
                          onToggleLike={handleToggleLikeLookBoard}
                          hideLikeVote
                        />
                        <div className="d-flex justify-content-end mt-1">
                          <button
                            type="button"
                            className={classes.inspirationBtn}
                            onClick={() =>
                              handleInspirationsModalOpen(
                                lookBoardsData[lookBoardId].inspirationId
                              )
                            }
                          >
                            View inspiration
                          </button>
                        </div>
                      </div>
                    )
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <SingleInspirationModal
        open={inspirationsModalOpen}
        onClose={handleInspirationsModalClose}
        currentImage={imagesData[tempImageId]}
      />
    </>
  );
};

export default UserDetails;
