import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import ImageItem from 'modules/curateTheLook/createLookBoard/components/ImageItem/ImageItem';
import NoLookBoardsModal from 'modules/getTheLook/components/NoLookBoardsModal/NoLookBoardsModal';
import transformArrayToMap from 'utils/transformArrayToMap';
import LookBoardItem from 'modules/getTheLook/components/LookBoardItem';
import {
  toggleLikeLookBoardAction,
  toggleLikeProductAction,
  updateLookBoardsDataAction,
  voteLookBoardAction,
} from 'modules/getTheLook/store/actions';
import classes from 'modules/getTheLook/GetTheLook.module.scss';
import errorToastr from 'libs/toastr/errorToastr';
import { selectImageAction as selectImageCTL } from 'modules/curateTheLook/store/actions';
import { selectImageAction as selectImageRTL } from 'modules/requestTheLook/store/actions';
import { routesByName } from 'constants/routes';
import {
  getTheLookTabKeys,
  productsSortKeys,
} from 'modules/getTheLook/constants';
import ProductItem from 'modules/getTheLook/components/ProductItem';
import useCallbackRef from 'hooks/useCallbackRef';
import GTLSlider from 'modules/getTheLook/components/GTLSlider';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import { ReactComponent as Preloader } from 'assets/icons/preloader.svg';
import MobileImageItem from './MobileImageItem/MobileImageItem';
import productService from '../../product/productService';

const ListItem = ({
  image: {
    id,
    url,
    isLiked,
    shareUrl,
    media: { userId, hash },
    lookBoardsCount,
    approval,
    publish,
    slug,
  },
  onOpenImgModal,
  activeTab,
  lookBoards,
  products,
  users,
  toggleLikeLookBoard,
  toggleLikeProduct,
  voteLookBoard,
  createLookBoard,
  createRequest,
  selectImageForCTL,
  authenticated,
  syncInspirationImage,
}) => {
  const elementRef = useRef(null);

  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const isLookBoardsLoading = useSelector((state) => state.getTheLook.loading);
  const { mixMatchSort, productSort } = useSelector(
    (state) => state.getTheLook
  );

  const { pathname } = useLocation();
  const history = useHistory();

  const [sliderNode, sliderRef] = useCallbackRef();

  const [isLoading, setIsLoading] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [slideLength, setSlideLength] = useState(0);
  const [hover, setHover] = useState(false);
  const [similarProducts, setSimilarProducts] = useState([]);

  const sortProducts = useMemo(() => {
    const productsArr = Object.values(products)
      .filter((p) => p.approval === 'approved' && p.publish !== 'notPublish')
      .slice(0, 6);
    if (productsArr.length) {
      switch (mixMatchSort) {
        case productsSortKeys.bestMatch: {
          return productsArr;
        }
        case productsSortKeys.mostLikes: {
          return productsArr.sort((a, b) => b.likesCount - a.likesCount);
        }
        case productsSortKeys.priceLowToHigh: {
          return productsArr.sort((a, b) => a.price - b.price);
        }
        default: {
          return productsArr;
        }
      }
    } else return [];
  }, [products, mixMatchSort]);

  const mixedProducts = useMemo(() => {
    if (productSort === 'all') {
      const result = [...similarProducts, ...sortProducts];
      switch (mixMatchSort) {
        case productsSortKeys.bestMatch: {
          return result;
        }
        case productsSortKeys.mostLikes: {
          return result.sort((a, b) => b.likesCount - a.likesCount);
        }
        case productsSortKeys.priceLowToHigh: {
          return result.sort((a, b) => a.price - b.price);
        }
        default: {
          return result;
        }
      }
    } else return sortProducts;
  }, [mixMatchSort, productSort, similarProducts, sortProducts]);

  const getSimilarProducts = useCallback(async () => {
    let resultArr = [];
    if (!similarProducts.length && sortProducts.length && !isLoading) {
      setIsLoading(true);
      try {
        resultArr = await productService.getSimilarProducts(
          sortProducts.map((item) => item.id),
          mixMatchSort
        );

        const flatRes = Object.values(resultArr).flat();

        const sortedSimilar = flatRes
          .filter(
            (p) => p.approval === 'approved' && p.publish !== 'notPublish'
          )
          .map((p) => ({ ...p, price: p.price * 100 }));
        setSimilarProducts(sortedSimilar);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading, mixMatchSort, similarProducts.length, sortProducts]);

  const handleToggleLikeLookBoard = useCallback(
    async (lookBoardId, likeStatus) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      try {
        await toggleLikeLookBoard(id, lookBoardId, likeStatus);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [authenticated, history, pathname, toggleLikeLookBoard, id]
  );

  const handleToggleLikeProduct = useCallback(
    async (productId, likeStatus) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      try {
        await toggleLikeProduct(id, productId, likeStatus);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [authenticated, history, pathname, toggleLikeProduct, id]
  );

  const handleVoteLookBoard = useCallback(
    async (lookBoardId, voteValue) => {
      try {
        await voteLookBoard(id, lookBoardId, voteValue);
      } catch (e) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);
        // errorToastr('Error', e.message);
      }
    },
    [history, voteLookBoard, id]
  );

  const lookBoardsList = useMemo(
    () =>
      lookBoards?.length &&
      lookBoards
        .filter((lb) => lb.products.length)
        .map((lookBoard) => (
          <div key={lookBoard.id} className="px-1 h-100">
            <LookBoardItem
              lookBoard={lookBoard}
              user={users[lookBoard.userId]}
              products={products}
              hideLikeVote
              onToggleLike={handleToggleLikeLookBoard}
              onVote={handleVoteLookBoard}
              isOnSlider
            />
          </div>
        )),
    [
      handleToggleLikeLookBoard,
      handleVoteLookBoard,
      lookBoards,
      products,
      users,
    ]
  );

  const updateSlideLength = useCallback(() => {
    sliderNode.slickGoTo(0, true);
    setActiveSlideIndex(0);

    switch (activeTab) {
      case getTheLookTabKeys.lookBoardView: {
        setSlideLength(lookBoardsList.length);
        break;
      }
      case getTheLookTabKeys.productStream: {
        setSlideLength(mixedProducts.length);
        break;
      }
      case getTheLookTabKeys.mixAndMatch: {
        setSlideLength(0);
        break;
      }
      default: {
        break;
      }
    }
  }, [activeTab, lookBoardsList, mixedProducts, sliderNode]);

  useEffect(() => {
    if (sliderNode) {
      updateSlideLength();
    }
    // eslint-disable-next-line
  }, [activeTab, sliderNode, mixedProducts]);

  const handleHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderNode.slickPrev();
  }, [sliderNode]);

  const handleSlideNext = useCallback(() => {
    sliderNode.slickNext();
  }, [sliderNode]);

  const handleCreateLookBoard = useCallback(() => {
    createLookBoard(id);
    history.push(routesByName.curateTheLook.index);
  }, [createLookBoard, id, history]);

  const handleCreateRequest = useCallback(() => {
    createRequest(id);
    history.push(routesByName.requestTheLook.index);
  }, [createRequest, id, history]);

  const handleSelectImage = useCallback(() => {
    history.push(routesByName.getTheLook.details(id, slug));
  }, [history, id, slug]);

  const handleMoveToCTL = useCallback(() => {
    if (pathname === routesByName.getTheLook.index) {
      selectImageForCTL(id);
      history.push(`${routesByName.curateTheLook.canvas}/?tips=active`);
    } else {
      selectImageForCTL(id);
      history.push(routesByName.curateTheLook.index);
    }
  }, [selectImageForCTL, id, history, pathname]);

  const handleLookBoardsView = useCallback(
    (detailsId) =>
      history.push(routesByName.getTheLook.details(detailsId, slug)),
    [history, slug]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (
          entry.isIntersecting &&
          !Object.values(products).length &&
          activeTab === getTheLookTabKeys.productStream
        ) {
          syncInspirationImage([id]);
        }
      },
      { threshold: 0 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [activeTab, id, products]);

  useEffect(() => {
    if (
      !similarProducts.length &&
      sortProducts.length &&
      activeTab === getTheLookTabKeys.productStream
    )
      getSimilarProducts().then();
  }, [sortProducts.length, similarProducts.length, activeTab]);

  if (matchesMediaQuery) {
    return (
      <MobileImageItem
        id={id}
        url={url}
        slug={slug}
        userId={userId}
        hash={hash}
        isLiked={isLiked}
        isLoading={isLookBoardsLoading}
        shareUrl={shareUrl}
        onOpenPreview={onOpenImgModal}
        onSelectImage={handleLookBoardsView}
        lookBoardsExist={!!lookBoards?.length}
      />
    );
  }

  return (
    <div
      ref={elementRef}
      className={clsx('d-flex position-relative', {
        [classes.hoveredItem]: hover,
      })}
    >
      <div
        className={`${classes.leftPanelWrapper}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleBlur}
      >
        <ImageItem
          id={id}
          url={url}
          userId={userId}
          slug={slug}
          hash={hash}
          isLiked={isLiked}
          shareUrl={shareUrl}
          onOpenPreview={onOpenImgModal}
          onSelectImage={handleSelectImage}
          handleBlur={handleBlur}
          lookBoardsCount={lookBoardsCount}
          approval={approval}
          publish={publish}
        />
      </div>
      <div className="flex-fill pb-5 mb-1 d-flex align-items-center">
        <div className={classes.mainContainer}>
          {(!lookBoards || isLoading) && (
            <div className={classes.loader}>
              <Preloader />
            </div>
          )}
          {lookBoards && lookBoards.length === 0 && (
            <NoLookBoardsModal
              onCreateLookBoard={handleCreateLookBoard}
              onCreateRequest={handleCreateRequest}
            />
          )}
          {lookBoards && lookBoards.length > 0 && (
            <GTLSlider
              sliderRef={sliderRef}
              slideLength={slideLength}
              activeSlideIndex={activeSlideIndex}
              onSlidePrev={handleSlidePrev}
              onSlideNext={handleSlideNext}
              onSlideChange={handleSlideChange}
              onViewAll={handleSelectImage}
              handleMoveToCTL={handleMoveToCTL}
            >
              {activeTab === getTheLookTabKeys.lookBoardView && lookBoardsList}
              {activeTab === getTheLookTabKeys.productStream &&
                mixedProducts.map((product) => (
                  <div key={product.id} className="px-1 h-100">
                    <ProductItem
                      product={{ ...product }}
                      user={users[product.userId]}
                      onToggleLike={handleToggleLikeProduct}
                    />
                  </div>
                ))}
            </GTLSlider>
          )}
        </div>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  image: inspirationImageShape.isRequired,
  onOpenImgModal: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  lookBoards: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.shape({}),
  users: PropTypes.shape({}),
  toggleLikeLookBoard: PropTypes.func.isRequired,
  toggleLikeProduct: PropTypes.func.isRequired,
  voteLookBoard: PropTypes.func.isRequired,
  createLookBoard: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
  selectImageForCTL: PropTypes.func.isRequired,
  syncInspirationImage: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

ListItem.defaultProps = {
  lookBoards: null,
  products: {},
  users: {},
};

const mapStateToProps = (
  { getTheLook: { activeTab, lookBoardsData }, auth: { user } },
  { image: { id } }
) => ({
  activeTab,
  lookBoards: lookBoardsData[id]?.lookBoards.slice(0, 6),
  products:
    lookBoardsData[id] && transformArrayToMap(lookBoardsData[id].products),
  users: lookBoardsData[id] && transformArrayToMap(lookBoardsData[id].users),
  authenticated: Boolean(user),
});

const mapDispatchToProps = {
  toggleLikeLookBoard: toggleLikeLookBoardAction,
  toggleLikeProduct: toggleLikeProductAction,
  voteLookBoard: voteLookBoardAction,
  createLookBoard: selectImageCTL,
  createRequest: selectImageRTL,
  selectImageForCTL: selectImageCTL,
  syncInspirationImage: updateLookBoardsDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ListItem));
