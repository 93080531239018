import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import rtlForce from 'assets/img/rtl-access-modal.png';
import ctlForce from 'assets/img/ctl-access-2.png';
import { toggleForceRegisterModalAction } from 'modules/layout/store/actions';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import BasicModal from '../BasicModal/BasicModal';
import classes from './ForceRegisterModal.module.scss';

const types = {
  'RTL': {
    title: 'Request the look',
    description: `The &quot;Request the look&quot; feature is the&nbsp;<span class="fw-600 font-italic">fastest</span>&nbsp;way to source product ideas to &quot;Get the Look&quot; of inspiration image`,
    image: rtlForce,
    subTitle: 'Register first to avoid recreating your request',
  },
  'CTL': {
    title: 'Curate the look',
    description: `The &quot;Curate the look&quot; feature makes it&nbsp;<span class="fw-600 font-italic">fun & easy</span>&nbsp;to visualize coordinating items together. Simply drag & drop items from our product database or Pin any item online`,
    image: ctlForce,
    subTitle: 'Register first to avoid recreating your look board',
  },
}
const ForceRegister = ({ type }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const open = useSelector((state) => state.layout.forceRegisterRTLModal);

  const handleCloseModal = useCallback(() => {
    dispatch(toggleForceRegisterModalAction(false));
  }, [dispatch]);

  const handleOpenRegisterModal = useCallback(() => {
    handleCloseModal();
    history.push(`?${routesByName.auth.key}=${routesByName.auth.signUp}`);
  }, [handleCloseModal, history]);

  return (
    <BasicModal open={open} onClose={handleCloseModal} fullWidth scroll="body">
      <div className={classes.forceRegisterWrapper}>
        <h3 className={classes.forceRegisterWrapper__title}>
          {types[type].title}
        </h3>
        <p className="text-center mb-2">
          <div dangerouslySetInnerHTML={{ __html: types[type].description }} />
        </p>
        <div className={classes.forceRegisterWrapper__textBlock}>
          {types[type].subTitle}
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenRegisterModal}
          className={classes.forceRegisterWrapper__registerBtn}
        >
          REGISTER NOW
        </Button>
        <img src={types[type].image} alt="forceRegister" />
      </div>
    </BasicModal>
  );
};

ForceRegister.propTypes = {
  type: PropTypes.string.isRequired,
}

export default ForceRegister;
