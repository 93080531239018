import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import groupBy from 'lodash-es/groupBy';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import productService from 'modules/product/productService';
import currentUserService from 'modules/currentUser/currentUserService';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import errorToastr from 'libs/toastr/errorToastr';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import Button from 'components/ui/Button/Button';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  getInspirationImgUrl,
  getUserAvatarThumbUrl,
} from 'modules/app/store/selectors';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import SimilarGroupSlider from 'modules/lookBoard/SimilarGroupSlider';
import { routesByName } from 'constants/routes';
import useFollowings from 'hooks/useFollowings';
import ConfirmModal from 'components/modals/ConfirmModal';
import LookBoardShare from 'components/LookBoardShare/LookBoardShare';
import CustomSlider from 'components/CustomSlider/CustomSlider';
import ImagesPopover from 'components/modals/ImagesPopover/ImagesPopover';
import clsx from 'clsx';
import Slider from 'react-slick';
import transformArrayToMap from 'utils/transformArrayToMap';
import findObjectById from 'utils/findObjectById';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import classes from './LookBoardDetails.module.scss';
import MobileLookBoard from './MobileLookBoard';
import InspirationsModal from './components/InspirationsModal';
import { updateIILibraryAction } from '../inspirationImage/store/actions';
import LookBoardItem from '../getTheLook/components/LookBoardItem';
import { updateLookBoardsDataAction } from '../getTheLook/store/actions';
import { publishStatusKeys } from '../request/constants';
import showcaseClasses from '../dashboard/showcase/Showcase.module.scss';
import { voteAmbassadorLookBoardAction } from '../getTheLook/ambassadorPage/store/actions';
import BasicAvatar from '../../components/ui/BasicAvatar';

const LookBoardDetails = () => {
  const dispatch = useDispatch();
  const sliderRef = useRef(null);

  const currentUser = useSelector((state) => state.auth.user);
  const { styles, roomTypes, itemClasses } = useSelector(
    (state) => state.app.enums
  );
  const config = useSelector((state) => state.app.config);
  const { lookBoardsData } = useSelector((state) => state.getTheLook);
  const inspirationImageUrl = useMemo(() => getInspirationImgUrl(config), [
    config,
  ]);
  const authenticated = useMemo(() => Boolean(currentUser), [currentUser]);
  const authorizedUserId = useMemo(() => currentUser?.id, [currentUser]);
  const userAvatarThumbUrl = useMemo(() => getUserAvatarThumbUrl(config), [
    config,
  ]);
  const history = useHistory();
  const { pathname } = useLocation();
  const { lookBoardId, lookBoardSlug } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentLookBoard, setCurrentLookBoard] = useState(null);
  const [lookBoardProducts, setLookBoardProducts] = useState([]);
  const [lookBoardUser, setLookBoardUser] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [similarProductGroups, setSimilarProductGroups] = useState({});
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [inspirationsModalOpen, setInspirationsModalOpen] = useState(false);
  const [similarInspirationImages, setSimilarInspirationImages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [followed, handleFollowChange, confirmModalOpen] = useFollowings({
    followUserId: lookBoardUser?.id,
    followUserName: `${lookBoardUser?.firstName} ${lookBoardUser?.lastName}`,
  });

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const lookBoardStyles = useMemo(() => {
    if (!currentLookBoard) {
      return [];
    }
    return currentLookBoard.styles.map(
      (styleId) => findObjectById(styleId, styles)?.name
    );
  }, [currentLookBoard, styles]);

  const itemList = useMemo(() => {
    return lookBoardProducts
      .filter(
        ({ itemClassId }) =>
          findObjectById(itemClassId, itemClasses)?.singular !==
          'Item Class Not Specified'
      )
      .map(
        ({ itemClassId }) => findObjectById(itemClassId, itemClasses)?.singular
      )
      .join(', ');
  }, [lookBoardProducts, itemClasses]);

  const imgPreviewUrl = useMemo(() => {
    if (!currentImage) {
      return null;
    }

    const {
      media: { userId, hash },
    } = currentImage;

    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [currentImage, inspirationImageUrl]);

  const sliderSimilarLength = useMemo(() => {
    let totalItems = 0;

    Object.keys(lookBoardsData).forEach((key) => {
      const object = lookBoardsData[key];
      if (
        currentImage &&
        currentLookBoard &&
        key !== currentImage?.id.toString() &&
        Array.isArray(object.lookBoards)
      ) {
        totalItems += object.lookBoards
          ?.filter((lookboard) => lookboard.id !== currentLookBoard.id)
          .slice(0, 3).length;
      }
    });
    return totalItems;
  }, [currentImage, currentLookBoard, lookBoardsData]);

  const sliderMainLength = useMemo(() => {
    let totalItems = 0;
    if (currentImage && currentLookBoard) {
      totalItems = lookBoardsData[currentImage.id]?.lookBoards?.filter(
        (item) => item.id !== currentLookBoard.id
      ).length;
    }
    return totalItems;
  }, [currentImage, currentLookBoard, lookBoardsData]);

  const mergedSimilarProducts = useMemo(() => {
    const mergedArr = Object.keys(similarProductGroups)
      .filter((key) => key !== '99') // without item class not specified
      .reduce((acc, key) => acc.concat(similarProductGroups[key]), []);
    const filteredProducts = lookBoardProducts.filter(
      (item) => item?.publish !== publishStatusKeys.notPublish
    );

    return [...filteredProducts, ...mergedArr];
  }, [lookBoardProducts, similarProductGroups]);

  const avatarUrl = useMemo(() => {
    if (lookBoardUser && lookBoardUser.avatar) {
      const { userId: currentUserId, hash: avatarHash } = lookBoardUser.avatar;
      return buildResourceUrl(userAvatarThumbUrl, currentUserId, avatarHash);
    }
    return null;
  }, [lookBoardUser, userAvatarThumbUrl]);

  const getSimilarProducts = useCallback(async (productsArr, lookBoard) => {
    const res = await productService.getSimilarProducts(lookBoard.products);
    const similarProducts = Object.values(res).flat();
    const groupedFilteredProducts = groupBy(similarProducts, 'itemClassId');
    setSimilarProductGroups(groupedFilteredProducts);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { result: lookBoard } = await lookBoardService.getLookBoardById(
          lookBoardId
        );
        const productsPromise = productService.getProductsByLookBoardId(
          lookBoard.products,
          lookBoard.id
        );

        const getUserPromise = currentUserService.getUserById(lookBoard.userId);

        const [user, productsArr] = await Promise.all([
          getUserPromise,
          productsPromise,
        ]);

        const productsMap = transformArrayToMap(productsArr);
        const preparedProductList = lookBoard.products.map(
          (id) => productsMap[id]
        );

        if (lookBoard.inspirationId) {
          const {
            result: inspirationImage,
          } = await inspirationImageService.getImageById(
            lookBoard.inspirationId
          );
          setCurrentImage(inspirationImage);

          const preparedSubStyles = {};
          inspirationImage.subStyles.forEach((subStyle) => {
            preparedSubStyles[subStyle] = 1;
          });
          const filterParams = {
            styles: inspirationImage.styles,
            colors: inspirationImage.colors,
            ...(inspirationImage.roomTypeId
              ? { roomTypes: [inspirationImage.roomTypeId] }
              : {}),
            ...(inspirationImage.itemClassId
              ? { itemClasses: [inspirationImage.itemClassId] }
              : {}),
            subStyles: preparedSubStyles,
          };

          const [
            library,
            result,
          ] = await inspirationImageService.getSimilarImages({
            source: imageSourceKeys.all,
            ...filterParams,
          });
          dispatch(updateIILibraryAction(library));
          const similarIds = [
            inspirationImage,
            ...result
              .filter((item) => item.id !== inspirationImage.id)
              .slice(0, 5),
          ].map(({ id }) => id);

          setSimilarInspirationImages(similarIds);
          dispatch(updateLookBoardsDataAction(similarIds));
        }

        setCurrentLookBoard(lookBoard);
        setLookBoardProducts(preparedProductList);
        setLookBoardUser(user);
        setLoading(false);
        await getSimilarProducts(productsArr, lookBoard);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    })();

    // eslint-disable-next-line
  }, [lookBoardId]);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleToggleLikeProduct = useCallback(
    async (productId, isLiked) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      await productService.toggleLike(productId, isLiked);
      const updatedProducts = lookBoardProducts.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            isLiked,
          };
        }
        return product;
      });
      setLookBoardProducts(updatedProducts);
    },
    [authenticated, lookBoardProducts, history, pathname]
  );

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleInspirationsModalClose = useCallback(() => {
    setInspirationsModalOpen(false);
  }, []);

  const handleLikeLookBoard = useCallback(
    async (lookBoard) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      const { id, isLiked } = lookBoard;
      const newLikedStatus = Number(!isLiked);
      await lookBoardService.toggleLike(id, newLikedStatus);
      const updatedLookBoard = {
        ...lookBoard,
        isLiked: newLikedStatus,
      };
      if (id === currentLookBoard.id) setCurrentLookBoard(updatedLookBoard);
      else {
        dispatch(updateLookBoardsDataAction(similarInspirationImages));
      }
    },
    [
      authenticated,
      currentLookBoard,
      dispatch,
      history,
      pathname,
      similarInspirationImages,
    ]
  );

  const handleVoteLookBoard = useCallback(
    async (lookBoardIdValue, voteValue) => {
      try {
        await dispatch(
          voteAmbassadorLookBoardAction(lookBoardIdValue, voteValue)
        );
        dispatch(updateLookBoardsDataAction(similarInspirationImages));

        // setLookBoardsLibrary((prevState) => ({
        //   ...prevState,
        //   [lookBoardIdValue]: { ...prevState[lookBoardIdValue], isVoted: true },
        // }));
      } catch (e) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);

        // errorToastr('Error', e.message);
      }
    },
    [dispatch, similarInspirationImages, history]
  );

  const handleClickProduct = useCallback(
    (productId, slug) => {
      history.push(routesByName.product.details(productId, slug));
    },
    [history]
  );

  useEffect(() => {
    return () => {
      const metaTags = document.querySelectorAll(`meta[id="helmetMeta"]`);
      const metaTitle = document.querySelector(`title[id="helmetMeta"]`);

      if (metaTitle) {
        metaTitle.textContent = 'PinTheLook';
      }
      if (metaTags) {
        metaTags.forEach((tag) => tag.remove());
      }
    };
  }, []);

  useEffect(() => {
    if (lookBoardSlug === ':lookBoardSlug' && currentLookBoard) {
      history.replace(
        routesByName.lookBoard.details(lookBoardId, currentLookBoard.slug)
      );
    }
  }, [currentLookBoard, history, lookBoardId, lookBoardSlug]);

  const renderMeta = useCallback(() => {
    return (
      <Helmet>
        <title id="helmetMeta">{currentLookBoard?.title || 'PinTheLook'}</title>
        <meta
          id="helmetMeta"
          name="keywords"
          content={currentLookBoard?.title || 'PinTheLook'}
        />
        <meta
          id="helmetMeta"
          name="description"
          content={currentLookBoard?.description || ''}
        />
        <meta
          id="helmetMeta"
          property="og:image"
          content="https://dp9tl72fvj0ab.cloudfront.net/look-board-seo-preview.jpg"
        />
        <meta
          id="helmetMeta"
          property="twitter:image"
          content="https://dp9tl72fvj0ab.cloudfront.net/look-board-seo-preview.jpg"
        />
      </Helmet>
    );
  }, [currentLookBoard]);

  const handleShareClick = useCallback(async () => {
    await lookBoardService.shareLookBoard(currentLookBoard?.id);
  }, [currentLookBoard]);

  const handleCloseImagePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const fullName = useMemo(
    () =>
      lookBoardUser?.displayName ??
      `${lookBoardUser?.firstName} ${lookBoardUser?.lastName}`,
    [lookBoardUser]
  );

  return !loading ? (
    <>
      {renderMeta()}
      <div className={classes.root}>
        {matchesMediaQuery ? (
          <div className="mt-2 mb-3 d-flex align-items-center" onClick={goBack}>
            <SpriteIcon
              name="left-arrow"
              size="sm"
              className="mr-1 primary-color"
            />
            <span className={classes.smallText}>Back</span>
          </div>
        ) : (
          <div className="d-flex align-items-center mb-3">
            <IconButton variant="inverted-white" size="sm" onClick={goBack}>
              <SpriteIcon name="left-arrow" size="sm" />
            </IconButton>
            <span className="text-sm ml-1">Back</span>
          </div>
        )}
        {matchesMediaQuery && (
          <div className={classes.smallText}>
            <div className="primary-color mb-1">Tips:</div>
            <div className="text-small mb-1">
              <b>1.</b> Swipe Right to Scroll Products
            </div>
            <div className="text-small mb-3">
              <b>2.</b> Click on Product to get more details
            </div>
          </div>
        )}
        {/* <Button */}
        {/*  variant="outlined" */}
        {/*  inline */}
        {/*  size="sm" */}
        {/*  className="mb-1 text-uppercase" */}
        {/*  onClick={handleOpenImagePopover} */}
        {/* > */}
        {/*  Inspirations */}
        {/* </Button> */}

        <div className={clsx(!matchesMediaQuery && 'background-white mb-5')}>
          <div
            className={clsx(
              'd-flex',
              matchesMediaQuery && 'flex-column align-items-center'
            )}
          >
            <div className={classes.lookBoardSection}>
              {matchesMediaQuery ? (
                <MobileLookBoard
                  items={lookBoardProducts}
                  handleClickProduct={handleClickProduct}
                  handleToggleLikeProduct={handleToggleLikeProduct}
                />
              ) : (
                <div className={classes.lookBoardBorder}>
                  <LookBoard
                    title={currentLookBoard.title}
                    color={currentLookBoard.color}
                    columns={currentLookBoard.columns}
                    items={lookBoardProducts}
                    collapsed={false}
                    interactive={false}
                    withHover
                    isMainLookBoard
                  />
                </div>
              )}
              {!matchesMediaQuery && (
                <div
                  className={clsx(
                    'd-flex align-items-center py-2',
                    currentImage
                      ? 'justify-content-between'
                      : 'justify-content-end'
                  )}
                >
                  {currentImage && (
                    <div className="d-flex justify-content-end" />
                  )}
                  <IconButton
                    variant="inverted-grey"
                    color="primary"
                    size="sm"
                    onClick={() => handleLikeLookBoard(currentLookBoard)}
                  >
                    <SpriteIcon
                      name={currentLookBoard.isLiked ? 'like-filled' : 'like'}
                      size="sm"
                    />
                  </IconButton>
                </div>
              )}
            </div>
            <div className={classes.infoSection}>
              <h3 className={classes.lookBoardTitle}>
                {currentLookBoard.title}
              </h3>
              <p className={`${classes.lookBoardDescription} mb-3`}>
                {currentLookBoard.description}
              </p>
              <div className="d-flex align-items-center mb-3">
                <p className="text-sm font-semi-bold mr-2">Share</p>
                <LookBoardShare
                  iconSize="sm"
                  pageUrl={currentLookBoard.shareUrl}
                  onShareClick={handleShareClick}
                  lookBoardId={currentLookBoard.id}
                />
              </div>
              <div className={`${classes.bordered} text-sm mb-3`}>
                <div className="d-flex mb-2">
                  <span className="font-semi-bold mr-2">Styles:</span>
                  <span className="font-italic">
                    {lookBoardStyles.join(', ')}
                  </span>
                </div>
                {currentImage && (
                  <div className="d-flex mb-2">
                    {currentImage.type === imageTypeKeys.room ? (
                      <>
                        <span className="font-semi-bold mr-2">Room Type:</span>
                        <span className="font-italic">
                          {roomTypes[currentImage.roomTypeId].name}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="font-semi-bold mr-2">Item Class:</span>
                        <div className="font-italic">
                          {currentLookBoard.itemClasses?.map((item, idx) => (
                            <span key={item}>
                              {itemClasses[item].name}{' '}
                              {idx === currentLookBoard.itemClasses.length - 1
                                ? ''
                                : ','}{' '}
                            </span>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div className="d-flex">
                  <span className="font-semi-bold mr-2">Items:</span>
                  <span className="font-italic">{itemList}</span>
                </div>
              </div>
              {lookBoardUser && (
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="flex-fill d-flex align-items-center text-ellipsis mr-2">
                    <BasicAvatar
                      firstName={lookBoardUser.firstName}
                      lastName={lookBoardUser.lastName}
                      src={avatarUrl}
                    />
                    <p className="text-sm ml-1 text-ellipsis font-semi-bold">
                      {fullName}
                    </p>
                  </div>
                  {lookBoardUser.id !== authorizedUserId && (
                    <Button
                      inline
                      color="secondary"
                      variant={followed ? 'contained' : 'outlined'}
                      size="sm"
                      onClick={handleFollowChange}
                    >
                      {followed ? 'Following' : 'Follow'}
                    </Button>
                  )}
                </div>
              )}
            </div>
            {currentImage?.publish === 'publishedPlus' && (
              <div className={classes.imgContainer}>
                <img
                  src={buildResourceUrl(
                    inspirationImageUrl.medium,
                    currentImage.media.userId,
                    currentImage.media.hash
                  )}
                  alt={currentImage.image_alt}
                />
                <div className={classes.imgInfoContainer}>
                  <div
                    className={`d-flex align-items-center ${classes.imgInfoType}`}
                  >
                    <span className={classes.typeCircle} />
                    <span>Inspiration image</span>
                  </div>
                  <p>{currentImage.title}</p>
                </div>
              </div>
            )}
          </div>
          {!matchesMediaQuery && Object.keys(mergedSimilarProducts).length ? (
            <div className="p-2">
              <div className="d-flex align-items-center mb-3">
                <span className="text-lg font-semi-bold mr-4">
                  Items in This Style
                </span>
                <div className={classes.itemClassLine} />
              </div>
              <SimilarGroupSlider products={mergedSimilarProducts} />
            </div>
          ) : null}
        </div>
        {matchesMediaQuery && (
          <>
            <h3 className={`${classes.lookBoardTitle} mb-3`}>
              Similar Look Boards
            </h3>
            <Slider
              className={showcaseClasses.featuredImageSlider}
              ref={sliderRef}
              arrows={false}
              slidesToShow={1}
              slidesToScroll={1}
              infinite={false}
              dots
              customPaging={(i) => <SpriteIcon name="dot" size="xs" key={i} />}
            >
              {lookBoardsData[currentImage.id]?.lookBoards?.map((lookBoard) => {
                if (lookBoard.id !== currentLookBoard.id) {
                  return (
                    <div key={lookBoard.id} className="px-1">
                      <LookBoardItem
                        lookBoard={lookBoard}
                        products={transformArrayToMap(
                          lookBoardsData[currentImage.id].products
                        )}
                        user={
                          transformArrayToMap(
                            lookBoardsData[currentImage.id].users
                          )[lookBoard.userId]
                        }
                        onToggleLike={() => handleLikeLookBoard(lookBoard)}
                        onVote={handleVoteLookBoard}
                        isFeaturedSlider
                      />
                    </div>
                  );
                }
                return null;
              })}
            </Slider>
          </>
        )}
        {!matchesMediaQuery && Object.keys(similarProductGroups).length > 0 && (
          <>
            <h3 className={`${classes.lookBoardTitle} mb-3`}>
              Similar Look Boards
            </h3>
            {Object.keys(lookBoardsData).length &&
            lookBoardsData[currentImage.id] ? (
              <CustomSlider sliderLength={sliderMainLength}>
                {lookBoardsData[currentImage.id].lookBoards?.map(
                  (lookBoard) => {
                    if (lookBoard.id !== currentLookBoard.id) {
                      return (
                        <div key={lookBoard.id} className="px-1">
                          <LookBoardItem
                            lookBoard={lookBoard}
                            products={transformArrayToMap(
                              lookBoardsData[currentImage.id].products
                            )}
                            user={
                              transformArrayToMap(
                                lookBoardsData[currentImage.id].users
                              )[lookBoard.userId]
                            }
                            hideLikeVote
                            onToggleLike={() => handleLikeLookBoard(lookBoard)}
                            onVote={handleVoteLookBoard}
                          />
                        </div>
                      );
                    }
                    return null;
                  }
                )}
              </CustomSlider>
            ) : null}
            {similarInspirationImages.filter((item) => item !== currentImage.id)
              .length && currentImage ? (
              <CustomSlider sliderLength={sliderSimilarLength}>
                {similarInspirationImages
                  .filter((item) => item !== currentImage.id)
                  .map((similarImage) => {
                    return (
                      Object.keys(lookBoardsData).length &&
                      lookBoardsData[similarImage]?.lookBoards
                        .slice(0, 3)
                        .map((lookBoard) => {
                          if (
                            lookBoard &&
                            lookBoard.id !== currentLookBoard.id
                          ) {
                            return (
                              <div key={lookBoard.id} className="px-1">
                                <LookBoardItem
                                  lookBoard={lookBoard}
                                  products={transformArrayToMap(
                                    lookBoardsData[similarImage]?.products
                                  )}
                                  user={
                                    transformArrayToMap(
                                      lookBoardsData[similarImage].users
                                    )[lookBoard.userId]
                                  }
                                  hideLikeVote
                                  onToggleLike={() => {}}
                                  onVote={() => {}}
                                />
                              </div>
                            );
                          }
                          return null;
                        })
                    );
                  })}
              </CustomSlider>
            ) : null}
          </>
        )}
      </div>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
      <ConfirmModal
        onClose={handleFollowChange}
        open={confirmModalOpen}
        title="Are you sure you want to unfollow user?"
      />
      <InspirationsModal
        open={inspirationsModalOpen}
        onClose={handleInspirationsModalClose}
        images={similarInspirationImages}
        currentImage={currentImage}
      />
      <ImagesPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseImagePopover}
        images={similarInspirationImages}
        currentImage={currentImage}
      />
    </>
  ) : (
    <div className="text-center p-5">Loading...</div>
  );
};

export default LookBoardDetails;
