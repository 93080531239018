import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import img from 'assets/img/get-the-look-page/MixMatch.png';
import { useHistory } from 'react-router-dom';
import classes from './RegisterForMixAndMatchModal.module.scss';
import { routesByName } from '../../../constants/routes';

const RegisterForMixAndMatchModal = ({ open, onClose }) => {
  const history = useHistory();

  const onRegister = () => {
    onClose();
    history.push(`?${routesByName.auth.key}=${routesByName.auth.signUp}`);
  };
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      classes={{ root: classes.modal }}
      scroll="body"
      maxWidth="md"
    >
      <div className="d-flex flex-column">
        <div className={classes.title}>MIX & MATCH</div>
        <span className="px-5">
          The &quot;Mix & Match&quot; feature is the best way to get the look
          you love at just the right price. Sort by price or simply scroll
          through all options to find the prefect look
        </span>
        <div className={classes.regTitle}>
          REGISTER TO USE THE MIX & MATCH TOOL
        </div>
        <div className={classes.regBtn} onClick={onRegister}>
          REGISTER NOW
        </div>
        <img
          style={{ height: 700, width: 700, margin: '0 auto' }}
          src={img}
          alt="Mix and Match"
        />
      </div>
    </BasicModal>
  );
};

RegisterForMixAndMatchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RegisterForMixAndMatchModal;
