import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  imagePageTypes,
  imageSortKeys,
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { useHistory } from 'react-router-dom';
import Tab from 'components/ui/Tab/Tab';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import {
  toggleAddImageModalAction,
  toggleVerificationModalAction,
} from 'modules/layout/store/actions';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import {
  myImagesTabsConfig,
  myProductsTabKey,
} from 'modules/dashboard/constants';
import MyImagesComponent from 'modules/dashboard/myImages/MyImagesComponent';
import ImageDetailsModal from 'modules/dashboard/myImages/components/ImageDetailsModal/ImageDetailsModal';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import accountClasses from 'modules/account/Account.module.scss';
import MyProductsContainer from 'modules/dashboard/myProducts/MyProductsContainer';
import useCancelToken from 'hooks/useCancelToken';
import { setDashboardActiveTab } from 'modules/dashboard/store/actions';
import classes from './MyImages.module.scss';
import appConfig from '../../../constants/appConfig';

// TODO: Get limit value from application config
const limit = 10;

const initialSearchParams = {
  source: imageSourceKeys.my,
  subStyles: [],
  offset: 0,
  sort: imageSortKeys.newest,
  type: imageTypeKeys.all,
};

const MyImagesContainer = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const hasVerifiedEmail = useMemo(() => Boolean(user?.hasVerifiedEmail), [
    user,
  ]);

  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [imageList, setImageList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [imageDetails, setImageDetails] = useState(null);
  const [createCancelToken, isCancelled] = useCancelToken();
  const history = useHistory();
  const { activeTab } = useSelector((state) => state.dashboard);

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();
      const preparedSearchParams = {
        ...searchParams,
        type:
          searchParams.type === imageTypeKeys.all
            ? undefined
            : searchParams.type,
        pageType: imagePageTypes.myImages,
      };

      const [list] = await inspirationImageService.getImages(
        preparedSearchParams,
        {
          cancelToken,
        }
      );

      if (preparedSearchParams.sort === 'newest') {
        setImageList(Object.values(list).reverse());
      } else {
        setImageList(Object.values(list));
      }

      if (Object.values(list).length > 0) {
        setSearchParams((prevState) => ({
          ...prevState,
          offset: prevState.offset + Object.values(list).length,
        }));
      }

      setHasMore(Object.values(list).length === limit);

      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [searchParams, isCancelled, createCancelToken]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();

      const preparedSearchParams = {
        ...searchParams,
        type:
          searchParams.type === imageTypeKeys.all
            ? undefined
            : searchParams.type,
        pageType: imagePageTypes.myImages,
      };

      const [list] = await inspirationImageService.getImages(
        preparedSearchParams,
        {
          cancelToken,
        }
      );

      setImageList((prevState) => [...prevState, ...Object.values(list)]);
      setSearchParams((prevState) => ({
        ...prevState,
        offset: prevState.offset + list.length,
      }));

      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      if (isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [searchParams, isCancelled, createCancelToken]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  const handleTabChange = useCallback(
    (tab) => {
      dispatch(setDashboardActiveTab(tab));
      if (tab !== myProductsTabKey && tab !== searchParams.source) {
        setSearchParams((prevState) => ({
          ...prevState,
          source: tab,
          offset: 0,
        }));
      }
    },
    [searchParams.source, dispatch]
  );

  const handleChangeImgType = useCallback((type) => {
    setSearchParams((prevState) => ({ ...prevState, type, offset: 0 }));
  }, []);

  const handleChangeSortType = useCallback((sort) => {
    setSearchParams((prevState) => ({ ...prevState, sort, offset: 0 }));
  }, []);

  const handleUnlikeImg = useCallback(
    async ({ currentTarget }) => {
      const imgId = currentTarget.getAttribute('data-img-id');
      try {
        await inspirationImageService.toggleLike(imgId, 0);
        const updatedImageList = imageList.filter(
          ({ id }) => id !== Number.parseInt(imgId, 10)
        );
        setImageList(updatedImageList);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [imageList]
  );

  const handleDeleteImg = useCallback(
    async (imgId) => {
      try {
        await inspirationImageService.deleteAddedImage(imgId);
        const updatedImageList = imageList.filter(
          ({ id }) => id !== Number.parseInt(imgId, 10)
        );
        setImageList(updatedImageList);
        successToastr('Success', 'Image successfully deleted');
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [imageList]
  );

  const handleAddImageModalOpen = useCallback(() => {
    if (!hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    dispatch(toggleAddImageModalAction(true));
  }, [dispatch, hasVerifiedEmail]);

  const handleOpenDetail = useCallback((data) => {
    setImageDetails(data);
    setDetailModalOpen(true);
  }, []);

  const handleDetailModalClose = useCallback(() => {
    setImageDetails(null);
    setDetailModalOpen(false);
  }, []);

  const handleSubmitEditImage = useCallback(
    async (formValues) => {
      try {
        await inspirationImageService.updateImage(imageDetails.id, formValues);
        handleDetailModalClose();
        setSuccessModalOpen(true);
        setSearchParams((prevState) => ({
          ...prevState,
          offset: 0,
        }));
      } catch (e) {
        errorToastr(e.generalError);
        return { ...e.validationErrors };
      }
      return true;
    },
    [imageDetails, handleDetailModalClose]
  );

  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalOpen(false);
  }, []);

  useEffect(() => {
    if (history.location.search.includes('update=true')) {
      history.push(history.location.pathname);
      setSearchParams((prevState) => ({
        ...prevState,
        offset: 0,
      }));
    }
  }, [history, history.location.search]);

  return (
    <>
      <div>
        {!user?.ambassador &&
          user?.productCount >= appConfig.productCountMax && (
            <div className={classes.infoProductMaxWrapper}>
              Alert: Product Max reached. Delete Products to make space.
            </div>
          )}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className={accountClasses.title}>My Images</p>
          <Button size="sm" inline onClick={handleAddImageModalOpen}>
            <SpriteIcon name="pin" style={{ marginLeft: -8 }} /> Add Image
          </Button>
        </div>
      </div>
      <div className={classes.infoDeleteImagesWrapper}>
        Unused or Unapproved Inspiration Images and E-commerce Products will be
        Deleted in 60 Days.
      </div>
      <div className="d-flex">
        {myImagesTabsConfig.map(({ name, title }) => (
          <Tab
            key={name}
            title={title}
            isActive={name === activeTab}
            name={name}
            onToggle={handleTabChange}
          />
        ))}
      </div>
      {activeTab === myProductsTabKey ? (
        <MyProductsContainer />
      ) : (
        <MyImagesComponent
          loading={loading}
          hasMore={hasMore}
          loadMore={loadMore}
          imgList={imageList}
          currentSource={searchParams.source}
          currentImgType={searchParams.type}
          currentSortType={searchParams.sort}
          onChangeImgType={handleChangeImgType}
          onChangeSortType={handleChangeSortType}
          onUnlike={handleUnlikeImg}
          onDeleteAddedImage={handleDeleteImg}
          onOpenDetail={handleOpenDetail}
        />
      )}
      {imageDetails && (
        <ImageDetailsModal
          open={detailModalOpen}
          onClose={handleDetailModalClose}
          title={imageDetails.title}
          url={imageDetails.url}
          type={imageDetails.type}
          description={imageDetails.description}
          roomTypeId={imageDetails.roomTypeId}
          itemClassId={imageDetails.itemClassId}
          imgUrl={imageDetails.imgUrl}
          editMode={imageDetails.editMode}
          showEdit={imageDetails.showEdit}
          onSave={handleSubmitEditImage}
        />
      )}
      <BasicModal
        open={successModalOpen}
        onClose={handleSuccessModalClose}
        maxWidth="xs"
        fullWidth
      >
        <div className="flex-center flex-column text-center">
          <h3
            className={`${classes.successTitle} font-title text-uppercase mb-2`}
          >
            You have edited <br /> information about <br /> this image
          </h3>
          <p className={`${classes.successDescription} text-sm font-italic`}>
            <span className="primary-color">After Validation</span> Your
            uploaded Image Will <br /> Appear in the{' '}
            <span className="primary-color">Get the Look</span> Section
          </p>
        </div>
      </BasicModal>
    </>
  );
};

export default MyImagesContainer;
