/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { routesByName } from 'constants/routes';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  accountTypes,
  businessTypeOptions,
  signUpFormFields,
} from 'modules/auth/signUp/constants';
import RadioInputWrapper from 'components/finalFormWrappers/RadioInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import { getEnumAsSelectOptions } from 'modules/app/store/selectors';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import CheckboxInputWrapper from 'components/finalFormWrappers/CheckboxInputWrapper';
import PasswordInputWrapper from 'components/finalFormWrappers/PasswordInputWrapper';
import { toggleTermsModalAction } from 'modules/layout/store/actions';
import PasswordTooltip from 'modules/auth/signUp/components/PasswordTooltip';
import AutoCompleteCustomWrapper from 'components/finalFormWrappers/AutoCompleteCustomWrapper';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { reCaptchaActions } from 'constants/V3CaptchaActions';
import classes from './SignUp.module.scss';

const SignUpComponent = ({
  initialValues,
  validateForm,
  onSubmit,
  loading,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const countriesEnum = useSelector((state) => state.app.enums.countries);

  const handleOpenTermsModal = useCallback(() => {
    dispatch(toggleTermsModalAction(true));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    async (formValues) => {
      try {
        let reCaptchaToken;
        if (!executeRecaptcha||typeof executeRecaptcha!=="function") {
          reCaptchaToken = null;
        }
        reCaptchaToken = await executeRecaptcha(reCaptchaActions.signUp);
        onSubmit({ ...formValues, reCaptchaToken });
      }catch (e) {
        console.log(e);
      }

    },
    [executeRecaptcha, onSubmit]
  );

  const countries = useMemo(() => getEnumAsSelectOptions(countriesEnum), [
    countriesEnum,
  ]);

  return (
    <>
      <div className={clsx(classes.title, 'mb-2')}>
        Register now
        <p className={classes.subInnerTitle}>
          to view curated looks you’ll love
        </p>
      </div>
      <p className={clsx(classes.subTitle, 'mb-3')}>
        Already have an account?{' '}
        <Link
          to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`}
          className="primary-color"
        >
          Sign In!
        </Link>
      </p>
      <Form
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={handleSubmitForm}
        render={({ values, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormControl component="fieldset">
              <RadioGroup>
                <div className="d-flex mb-3">
                  <FormControlLabel
                    label="Personal"
                    classes={{
                      root: 'mr-5',
                      label: clsx({
                        'font-semi-bold': values.type === accountTypes.personal,
                      }),
                    }}
                    control={
                      <Field
                        name={signUpFormFields.type}
                        type="radio"
                        value={accountTypes.personal}
                        component={RadioInputWrapper}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Business"
                    classes={{
                      label: clsx({
                        'font-semi-bold': values.type === accountTypes.business,
                      }),
                    }}
                    control={
                      <Field
                        name={signUpFormFields.type}
                        type="radio"
                        value={accountTypes.business}
                        component={RadioInputWrapper}
                      />
                    }
                  />
                </div>
              </RadioGroup>
            </FormControl>
            <Field
              required
              label="First Name"
              name={signUpFormFields.firstName}
              component={TextInputWrapper}
            />
            <Field
              required
              label="Last Name"
              name={signUpFormFields.lastName}
              component={TextInputWrapper}
            />
            {values.type === accountTypes.business && (
              <>
                <Field
                  required
                  label="Company Name"
                  name={signUpFormFields.businessName}
                  component={TextInputWrapper}
                />
                <Field
                  required
                  freeSolo
                  label="Business Type"
                  name={signUpFormFields.businessType}
                  options={businessTypeOptions}
                  component={AutoCompleteCustomWrapper}
                />
                <Field
                  label="Website Url (Optional)"
                  name={signUpFormFields.businessUrl}
                  component={TextInputWrapper}
                />
              </>
            )}
            <Field
              required
              label="Email"
              type="email"
              name={signUpFormFields.email}
              component={TextInputWrapper}
            />
            <Field
              required
              label="Zip Code"
              name={signUpFormFields.zipCode}
              component={TextInputWrapper}
            />
            <Field
              required
              label="Country"
              name={signUpFormFields.countryId}
              component={SelectInputWrapper}
              options={countries}
            />
            <p className={classes.passwordLabel}>
              Password requires 8+ characters, including a number.
            </p>
            <Field
              required
              label="Password"
              type="password"
              name={signUpFormFields.password}
              component={PasswordInputWrapper}
              InputProps={{
                endAdornment: <PasswordTooltip />,
              }}
            />
            <Field
              required
              label="Re-Enter Password"
              type="password"
              name={signUpFormFields.passwordRepeat}
              component={PasswordInputWrapper}
              InputProps={{
                endAdornment: <PasswordTooltip />,
              }}
            />
            <FormControlLabel
              label={
                <>
                  I agree to{' '}
                  <button
                    onClick={handleOpenTermsModal}
                    className="primary-color link-underline btn-clear cursor-pointer text-sm"
                    type="button"
                  >
                    Pin The Look&apos;s terms
                  </button>
                </>
              }
              control={
                <Field
                  required
                  type="checkbox"
                  name={signUpFormFields.terms}
                  component={CheckboxInputWrapper}
                />
              }
            />
            <div className="text-center mt-2">
              <FinalFormSubmitButton
                size="sm"
                inline
                disabled={!values.terms || loading}
              >
                Submit
              </FinalFormSubmitButton>
            </div>
          </form>
        )}
      />
    </>
  );
};

SignUpComponent.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  validateForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SignUpComponent;
