import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash-es/filter';
import {
  getInspirationImgUrl,
  getRoomTypeName,
  getStyleNames,
} from 'modules/app/store/selectors';
import {
  resetCanvasAction,
  resetCurateStateAction,
} from 'modules/curateTheLook/store/actions';
import buildResourceUrl from 'utils/buildResourceUrl';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import {
  lookBoardDetailsFormFields,
  lookBoardStatusRadioOptions,
} from 'modules/curateTheLook/constants';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import LookBoardInfo from 'modules/curateTheLook/components/LookBoardInfo/LookBoardInfo';
import SocialShare from 'components/SocialShare/SocialShare';
import AmbassadorBanner from 'modules/curateTheLook/lookBoardSuccess/components/AmbassadorBanner/AmbassadorBanner';
import StepsBanner from 'modules/curateTheLook/lookBoardSuccess/components/StepsBanner/StepsBanner';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import DetailsPreview from 'modules/requestTheLook/components/DetailsPreview/DetailsPreview';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import useItemClassesByIds from 'modules/app/hooks/useItemClassesByIds';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import classes from './LookBoardSuccess.module.scss';
import lookBoardService from '../../lookBoard/lookBoardService';

const LookBoardSuccessContainer = () => {
  const dispatch = useDispatch();

  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const enums = useSelector((state) => state.app.enums);
  const products = useSelector((state) => state.product.library);
  const inspirationImageLibrary = useSelector(
    (state) => state.inspirationImage.library
  );
  const { colors, roomTypes, itemClasses, styles } = enums;
  const {
    lookBoardData,
    lookBoardDetailsFormValues,
    lookBoardStatus,
    requestInfo,
  } = useSelector((state) => state.curateTheLook);

  const selectedImage = useMemo(
    () => inspirationImageLibrary[lookBoardData.inspirationImageId],
    [inspirationImageLibrary, lookBoardData.inspirationImageId]
  );
  const roomType = useMemo(
    () => getRoomTypeName(selectedImage?.roomTypeId, enums),
    [enums, selectedImage]
  );
  const imageStyles = useMemo(
    () => (selectedImage ? getStyleNames(selectedImage?.styles, enums) : null),
    [enums, selectedImage]
  );

  const [state, setState] = useState({});
  const [collapsed, setCollapsed] = useState(true);
  const [activeTab, setActiveTab] = useState(
    lookBoardDetailsFormFields.description
  );
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const itemClassesList = useItemClassesByIds(state.requestInfo?.priorityItems);
  const itemType = useMemo(() => {
    if (state.selectedImage) {
      return state.selectedImage.type === imageTypeKeys.room ? 'Room' : 'Item';
    }
    return '';
  }, [state.selectedImage]);

  const handleDetailModalOpen = useCallback(() => {
    setDetailsModalOpen(true);
  }, []);

  const handleDetailModalClose = useCallback(() => {
    setDetailsModalOpen(false);
  }, []);

  const handleToggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const handleChangeTab = useCallback(
    ({ currentTarget }) => {
      const { tab } = currentTarget.dataset;
      if (tab !== activeTab) {
        setActiveTab(tab);
      }
    },
    [activeTab]
  );

  useEffect(() => {
    const productList =
      !lookBoardData || !products
        ? []
        : lookBoardData.products.map((id) => products[id]);

    const imgUrl = buildResourceUrl(
      inspirationImageUrl.medium,
      selectedImage?.media.userId,
      selectedImage?.media.hash
    );

    const colorList = selectedImage
      ? selectedImage.colors.map((id) => colors[id])
      : [];

    setState({
      lookBoardData,
      productList,
      lookBoardDetailsFormValues,
      selectedImage,
      imgUrl,
      colorList,
      lookBoardStatus,
      radioOptions: filter(lookBoardStatusRadioOptions, {
        value: lookBoardStatus,
      }),
      requestInfo,
      imageStyles,
      roomType,
    });
  }, [
    colors,
    imageStyles,
    inspirationImageUrl.medium,
    lookBoardData,
    lookBoardDetailsFormValues,
    lookBoardStatus,
    products,
    requestInfo,
    roomType,
    selectedImage,
  ]);

  useEffect(
    () => () => {
      dispatch(resetCurateStateAction());
      dispatch(resetCanvasAction());
    },
    //  eslint-disable-next-line
    []
  );

  const handleShareClick = useCallback(async () => {
    await lookBoardService.shareLookBoard(state.lookBoardData.id);
  }, [state]);

  return state.lookBoardDetailsFormValues ? (
    <section className={classes.root}>
      <div className="mb-2">
        <Link to={routesByName.curateTheLook.canvas}>
          <div className="d-inline-flex align-items-center mb-3">
            <IconButton variant="inverted-white" size="sm">
              <SpriteIcon name="left-arrow" size="sm" />
            </IconButton>
            <span className="primary-color text-sm ml-1">To Main Page</span>
          </div>
        </Link>
      </div>
      <h1 className={classes.title}>
        Great Job! Your Look Board has been Submitted
      </h1>
      <div className="d-flex mt-4">
        <div className={`${classes.lookBoardSection} mr-4`}>
          <LookBoard
            title={state.lookBoardData.title}
            color={lookBoardData.color}
            columns={state.lookBoardData.columns}
            items={state.productList}
            collapsed={collapsed}
            onToggleCollapse={handleToggleCollapse}
          />
        </div>
        <LookBoardInfo
          selectedImage={state.selectedImage}
          lookBoardDetailsFormValues={state.lookBoardDetailsFormValues}
          imgUrl={state.imgUrl}
          roomTypes={roomTypes}
          itemClasses={itemClasses}
          styles={styles}
          colorList={state.colorList}
          productList={state.productList}
          lookBoardStatus={state.lookBoardStatus}
          radioOptions={state.radioOptions}
          requestTitle={state.requestInfo?.title}
          onDetailsPreview={handleDetailModalOpen}
          activeTab={activeTab}
          onChangeTab={handleChangeTab}
        />
      </div>
      <div className="d-flex mt-4">
        <div className={`${classes.lookBoardSection} mr-4`}>
          <AmbassadorBanner />
        </div>
        <StepsBanner />
      </div>
      {state.requestInfo && (
        <BasicModal
          classes={{ root: 'rounded-0 p-0' }}
          open={detailsModalOpen}
          onClose={handleDetailModalClose}
          maxWidth="lg"
        >
          <div className="d-flex">
            <ImageDetails
              imgUrl={state.imgUrl}
              url={state.selectedImage.url}
              title={state.selectedImage.title}
              showInfo
            />
            <DetailsPreview
              title={state.requestInfo?.title}
              requestType={state.selectedImage.type}
              styles={state.imageStyles}
              priorityItems={itemClassesList}
              itemType={itemType}
              roomType={state.roomType}
              message={state.requestInfo?.message}
            />
          </div>
        </BasicModal>
      )}
    </section>
  ) : null;
};

export default LookBoardSuccessContainer;
