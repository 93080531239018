import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getProductImgUrl } from 'modules/app/store/selectors';
import {
  buildDroppableColumnsAction,
  changeStepAction,
  changeTemplateAction,
  removeItemFromCanvasAction,
  resetCanvasAction,
  saveDraftAction,
  updateLookBoardDataAction,
} from 'modules/curateTheLook/store/actions';
import customEvent from 'utils/customEvent';
import { UPDATE_SCROLLER_HEIGHT } from 'constants/customEventNames';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import ConfirmModal from 'components/modals/ConfirmModal';
import CanvasTitlePopover from 'modules/curateTheLook/createLookBoard/components/CanvasTitlePopover';
import EditableLookBoardHeader from 'modules/curateTheLook/createLookBoard/components/EditableLookBoardHeader/EditableLookBoardHeader';
import {toggleForceRegisterModalAction, toggleVerificationModalAction} from 'modules/layout/store/actions';
import {
  createLookBoardStepKeys,
  lookBoardTemplatesConfig,
} from 'modules/curateTheLook/constants';
import { ReactComponent as Column1 } from 'assets/icons/column-1.svg';
import { ReactComponent as Column2 } from 'assets/icons/column-2.svg';
import { ReactComponent as Column3 } from 'assets/icons/column-3.svg';
import SelectColorPopper from 'components/modals/SelectColorPopper/SelectColorPopper';
import useQuery from 'hooks/useQuery';
import classes from './CreateLookBoardCanvas.module.scss';

const columnToClass = {
  1: classes.single,
  2: classes.double,
  3: classes.triple,
};

const CreateLookBoardCanvas = ({ imageProductSize, setImageProductSize }) => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.app.config);
  const currentUser = useSelector((state) => state.auth.user);
  const { dndState, selectedTemplate, lookBoardData } = useSelector(
    (state) => state.curateTheLook
  );

  const productImgUrl = useMemo(() => getProductImgUrl(config), [config]);
  const droppableColumns = useMemo(
    () => Object.values(dndState.dynamicColumns),
    [dndState.dynamicColumns]
  );

  const history = useHistory();
  const { pathname, search } = useLocation();
  const queryParams = useQuery();
  const containerRef = useRef(null);
  const headerContainerRef = useRef(null);
  const [openedModal, setOpenedModal] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const [colorsAnchorEl, setColorsAnchorEl] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [productHeight, setProductHeight] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [lookBoardColor, setLookBoardColor] = useState(lookBoardData.color);

  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true);

  const handleResetModalOpen = useCallback(() => {
    setResetModalOpen(true);
  }, []);

  const { minItems, maxItems } = useMemo(() => {
    if (selectedTemplate) {
      return {
        minItems: selectedTemplate.columnCount,
        maxItems: 4 * selectedTemplate.columnCount,
      };
    }
    return { minItems: 0, maxItems: 0 };
  }, [selectedTemplate]);

  const columns = useMemo(
    () => [
      {
        id: 1,
        Icon: Column1,
      },
      {
        id: 2,
        Icon: Column2,
      },
      {
        id: 3,
        Icon: Column3,
      },
    ],
    []
  );

  useEffect(() => {
    if (containerRef.current) {
      const refWidth = containerRef.current.clientWidth;
      const refHeight = Math.floor(refWidth * (3 / 4)); // aspect ratio = 4:3
      const itemHeight = Math.floor(refHeight / 2);

      setContainerHeight(refHeight);
      setProductHeight(itemHeight);
      setImageProductSize(itemHeight);
    }
  }, [setImageProductSize]);

  const handleAddRow = useCallback(() => {
    const currentItemsLength = droppableColumns.length;
    dispatch(
      buildDroppableColumnsAction(
        selectedTemplate.columnCount,
        currentItemsLength
      )
    );
  }, [droppableColumns, selectedTemplate, dispatch]);

  const handleRemoveRow = useCallback(() => {
    const currentItemsLength = droppableColumns.length;
    dispatch(
      buildDroppableColumnsAction(
        selectedTemplate.columnCount,
        currentItemsLength,
        true
      )
    );
  }, [droppableColumns, selectedTemplate, dispatch]);

  const handleRemoveItem = useCallback(
    ({ currentTarget }) => {
      const columnId = currentTarget.getAttribute('data-column');
      dispatch(removeItemFromCanvasAction(columnId));
    },
    [dispatch]
  );

  const handleOpenInfoPopover = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleCloseInfoPopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleColorPickerOpen = useCallback(({ currentTarget }) => {
    setColorsAnchorEl(currentTarget);
  }, []);

  const handleColorPickerClose = useCallback(() => {
    setColorsAnchorEl(null);
  }, [setColorsAnchorEl]);

  const handleColorPickerSave = useCallback(() => {
    setColorsAnchorEl(null);
    dispatch(
      updateLookBoardDataAction({ ...lookBoardData, color: lookBoardColor })
    );
  }, [lookBoardColor, lookBoardData, dispatch]);

  const handleEnableEditMode = useCallback(
    (e) => {
      handleColorPickerOpen(e);
      setEditMode(true);
    },
    [handleColorPickerOpen]
  );

  const handleEnableTitleEditMode = useCallback(() => setTitleEditMode(true), [
    setTitleEditMode,
  ]);

  const handleDisableTitleEditMode = useCallback(
    ({ title }) => {
      setTitleEditMode(false);
      dispatch(updateLookBoardDataAction({ ...lookBoardData, title }));
    },
    [dispatch, lookBoardData, setTitleEditMode]
  );

  const handleResetCanvas = useCallback(
    (confirm) => {
      if (confirm) {
        dispatch(resetCanvasAction());
        setEditMode(false);
      }
      setResetModalOpen(false);
    },
    [dispatch]
  );

  const saveToDrafts = useCallback(async () => {
    try {
      if (!currentUser) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }

      if (!currentUser.hasVerifiedEmail) {
        dispatch(toggleVerificationModalAction(true));
        return;
      }

      await dispatch(saveDraftAction());
      successToastr('Success!', 'Look Board successfully saved to drafts');
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [currentUser, history, pathname, dispatch]);

  const goFurther = useCallback(() => {
    if (!currentUser) {
      if (!openedModal) {
        dispatch(toggleForceRegisterModalAction(true));
        setOpenedModal(true);
      } else {
        history.push(routesByName.curateTheLook.details);
      }
      return;
    }

    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }

    history.push(routesByName.curateTheLook.details);
  }, [currentUser, history, openedModal, dispatch]);

  const handleTemplateClick = useCallback(
    (id) => {
      dispatch(changeTemplateAction(lookBoardTemplatesConfig[id]));
    },
    [dispatch]
  );

  const getListStyle = (isDraggingOver) => {
    return {
      background: isDraggingOver ? 'lightblue' : 'white',
      height: productHeight,
    };
  };

  const getStyle = (styles) => {
    return {
      ...styles,
      height: imageProductSize,
    };
  };
  const handleApplyColors = useCallback((customColor, pickerColor) => {
    if (typeof customColor === 'number') {
      setLookBoardColor(pickerColor);
    } else {
      setLookBoardColor(customColor);
    }
  }, []);

  useEffect(() => {
    customEvent.trigger(UPDATE_SCROLLER_HEIGHT);
  }, [containerHeight]);

  useEffect(() => {
    const queryAuth = queryParams.get('auth');
    const querySkipped = queryParams.get('skipped');

    if (querySkipped === 'true' && queryAuth === 'sign-in') {
      dispatch(changeStepAction(createLookBoardStepKeys.addProducts));
      dispatch(resetCanvasAction());
    }
    // eslint-disable-next-line
  }, [dispatch, pathname, search]);

  useEffect(() => {
    setIsNextBtnDisabled(false);
    if (droppableColumns.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < droppableColumns.length; i++) {
        if (droppableColumns[i].product?.approval === 'rejected') {
          setIsNextBtnDisabled(true);
          break;
        }
      }
    }
  }, [droppableColumns]);

  return (
    <>
      {selectedTemplate && (
        <>
          <div className="text-sm mb-2 d-flex justify-content-between align-items-center">
            <span>
              <b>Expandable Template:</b> {selectedTemplate.columnCount}
              -Columns ({minItems}-{maxItems} Items)
            </span>
            <div className="d-flex justify-content-end align-items-center">
              {columns.map((Col) => (
                <div
                  key={Col.id}
                  className={clsx(
                    classes.columnIcon,
                    Col.id === selectedTemplate?.id && classes.active
                  )}
                  onClick={handleTemplateClick.bind(null, Col.id)}
                >
                  <Col.Icon />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className={`${classes.root} mb-2`}>
        <div ref={headerContainerRef}>
          {titleEditMode ? (
            <EditableLookBoardHeader
              color={lookBoardData.color}
              title={lookBoardData.title}
              onDisableEditMode={handleDisableTitleEditMode}
            />
          ) : (
            <div
              className={classes.title}
              style={{
                backgroundColor: colorsAnchorEl
                  ? lookBoardColor
                  : lookBoardData.color,
              }}
            >
              <span onClick={handleEnableTitleEditMode}>
                {lookBoardData.title}
              </span>
              <IconButton
                variant="inverted-white"
                color="secondary"
                size="xs"
                onClick={handleOpenInfoPopover}
                className={classes.infoBtn}
              >
                <SpriteIcon name="info" size="sm" />
              </IconButton>
            </div>
          )}
          <IconButton
            variant="inverted-white"
            color="secondary"
            size="sm"
            className={classes.editBtn}
            onClick={handleEnableEditMode}
          >
            <SpriteIcon name={editMode ? 'brush' : 'edit'} size="sm" />
          </IconButton>
          {editMode && (
            <SelectColorPopper
              currentColor={lookBoardColor}
              open={Boolean(colorsAnchorEl)}
              anchorEl={colorsAnchorEl}
              onClose={handleColorPickerClose}
              onSave={handleColorPickerSave}
              onColorChange={handleApplyColors}
            />
          )}
        </div>
        <div
          style={{ minHeight: containerHeight || 630 }}
          className="flex-fill d-flex flex-column"
          ref={containerRef}
        >
          {selectedTemplate ? (
            <div
              className={clsx(
                classes.droppableContainer,
                columnToClass[selectedTemplate.columnCount]
              )}
            >
              <div className={classes.infoBlock}>
                <div className={classes.infoIcon}>
                  <SpriteIcon name="info" size="sm" />
                </div>
                <p>{`You need to add ${minItems} - ${maxItems} items`}</p>
              </div>
              {droppableColumns.map(({ id: columnId, product }) => (
                <Droppable key={columnId} droppableId={columnId}>
                  {(
                    { innerRef, droppableProps, placeholder },
                    { draggingFromThisWith, isDraggingOver }
                  ) => {
                    return (
                      <div
                        ref={innerRef}
                        {...droppableProps}
                        className={clsx(classes.droppableItem, {
                          [classes.invisible]:
                            product && !draggingFromThisWith && isDraggingOver,
                        })}
                        style={getListStyle(isDraggingOver)}
                      >
                        {product && (
                          <Draggable draggableId={String(product.id)} index={0}>
                            {(
                              {
                                draggableProps,
                                dragHandleProps,
                                innerRef: draggableRef,
                              },
                              { isDragging }
                            ) => (
                              <div
                                {...draggableProps}
                                {...dragHandleProps}
                                ref={draggableRef}
                                className={classes.draggableItem}
                                style={getStyle(draggableProps.style)}
                              >
                                {!isDragging && (
                                  <IconButton
                                    className={classes.removeBtn}
                                    color="secondary"
                                    size="xs"
                                    data-column={columnId}
                                    onClick={handleRemoveItem}
                                  >
                                    <SpriteIcon name="cross" size="sm" />
                                  </IconButton>
                                )}
                                <div
                                  className={clsx({
                                    [classes.staticOverlay]:
                                      product.approval === 'rejected',
                                  })}
                                >
                                  {product.approval === 'rejected' && (
                                    <div className={classes.overlayRoot}>
                                      <p>Edits required</p>
                                      <div className={classes.topRightBorder} />
                                      <div
                                        className={classes.bottomLeftBorder}
                                      />
                                    </div>
                                  )}
                                  <img
                                    alt=""
                                    src={buildResourceUrl(
                                      productImgUrl.medium,
                                      product.media.userId,
                                      product.media.hash
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )}
                        {placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              ))}
              <div className={classes.canvasRowsBtn}>
                <IconButton
                  size="sm"
                  className="mr-1"
                  onClick={handleRemoveRow}
                  disabled={droppableColumns.length === minItems}
                >
                  <SpriteIcon name="minus" size="xs" />
                </IconButton>
                <IconButton
                  size="sm"
                  onClick={handleAddRow}
                  disabled={droppableColumns.length === maxItems}
                >
                  <SpriteIcon name="plus" size="xs" />
                </IconButton>
              </div>
            </div>
          ) : (
            <div className={classes.emptyTemplate}>
              <span>
                Select Image and
                <br />
                Drag & Drop Products Here
              </span>
            </div>
          )}
        </div>
      </div>
      {selectedTemplate && (
        <div className="d-flex justify-content-end align-items-center mb-3">
          <IconButton
            className="mr-1"
            variant="inverted-white"
            size="sm"
            onClick={handleResetModalOpen}
          >
            <SpriteIcon name="update-arrows" size="sm" />
          </IconButton>
          <span className="font-semi-bold primary-color text-sm">Reset</span>
        </div>
      )}
      <div className="d-flex justify-content-center mb-5">
        <Button
          variant="outlined"
          style={{ width: 'auto' }}
          className="mr-2"
          disabled={
            !selectedTemplate || lookBoardData.products.length < minItems
          }
          onClick={saveToDrafts}
        >
          <SpriteIcon name="dashboard" size="sm" className="mr-1" />
          Save to Drafts
        </Button>
        <Button
          style={{ width: 'auto' }}
          disabled={
            !selectedTemplate ||
            lookBoardData.products.length < minItems ||
            isNextBtnDisabled
          }
          onClick={goFurther}
        >
          Next: Look Board Details
        </Button>
      </div>
      <CanvasTitlePopover
        classes={{ root: classes.infoPopup }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseInfoPopover}
      />
      <ConfirmModal
        open={resetModalOpen}
        onClose={handleResetCanvas}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">reset</span> look board?
          </>
        }
      />
    </>
  );
};

CreateLookBoardCanvas.propTypes = {
  imageProductSize: PropTypes.number.isRequired,
  setImageProductSize: PropTypes.func.isRequired,
};

export default CreateLookBoardCanvas;
