import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routesByName } from 'constants/routes';
import mediaTypes from 'constants/mediaTypes';
import { imageSourceKeys } from 'constants/inspirationImageSearchParams';
import {
  createImageFieldKeys,
  createProductFieldKeys,
  ownershipValuesMap,
  permissionsValuesMap,
} from 'modules/pinMedia/constants';
import mediaService from 'modules/media/mediaService';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import productService from 'modules/product/productService';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import {
  toggleAddImageModalAction,
  toggleChooseMethodModalAction,
  togglePinMediaModalAction,
  toggleVerificationModalAction,
} from 'modules/layout/store/actions';
import FloatActionsBlock from 'modules/pinMedia/FloatActionsBlock/FloatActionsBlock';
import AddImageModal from 'modules/pinMedia/AddImageModal/AddImageModal';
import ChooseMethodModal from 'modules/pinMedia/ChooseMethodModal/ChooseMethodModal';
import UploadImageModal from 'modules/pinMedia/UploadImageModal/UploadImageModal';
import SaveImageModal from 'modules/pinMedia/SaveImageModal/SaveImageModal';
import { updateRequestIISearchParamsAction } from 'modules/requestTheLook/store/actions';
import useCustomEventListener from 'hooks/useCustomEventListener';
import { productTypeKeys } from 'constants/productSearchParams';
import {
  OPEN_PIN_PRODUCT_MODAL,
  PIN_IMAGE_FOR_REQUEST,
} from 'constants/customEventNames';
import { setDashboardActiveTab } from 'modules/dashboard/store/actions';
import {
  changeIsOwnImageAction,
  selectImageForFeatureAction,
  updateCreationImageStatusAction,
} from 'modules/inspirationImage/store/actions';
import {
  restoreCurateStateAction,
  saveInstantlyCreatedProductAction,
  updateIISearchParamsAction,
  updateProductSearchParamsAction,
} from 'modules/curateTheLook/store/actions';
import { myProductsTabKey } from 'modules/dashboard/constants';
import { localStorageKeys } from 'modules/app/constants';
import useQuery from 'hooks/useQuery';
import SaveImageModalExtension from './PinMediaExtension/SaveImageModalExtension';
import UploadExtensionModal from './UploadExtensionModal/UploadExtensionModal';
import RestrictAddImageModal from './RestrictAddImageModal/RestrictAddImageModal';
import appConfig from '../../constants/appConfig';

const PinMediaContainer = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const {
    addImageModal: addImageModalOpen,
    pinMediaModal: pinMediaModalOpen,
    chooseMethodModal: {
      isVisible: isVisibleChooseMethodModal,
      type: chooseMethodModalType,
    },
  } = useSelector((state) => state.layout);
  const authenticated = useMemo(() => Boolean(user), [user]);
  const hasVerifiedEmail = useMemo(() => Boolean(user?.hasVerifiedEmail), [
    user,
  ]);

  const { pathname } = useLocation();
  const history = useHistory();
  const queryParams = useQuery();
  const localStorageData = useRef({});

  const [newImage, setNewImage] = useState(null);
  const [newSiteSourceUrl, setNewSiteSourceUrl] = useState(null);
  const [currentMediaType, setCurrentMediaType] = useState(
    mediaTypes.inspirationImage
  );
  const [parsedProduct, setParsedProduct] = useState(null);
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);
  const [saveImageModalOpen, setSaveImageModalOpen] = useState(false);
  const [
    saveImageModalOpenExtension,
    setSaveImageModalOpenExtension,
  ] = useState(false);
  const [redirectRequired, setRedirectRequired] = useState(false);
  const [withDrop, setWithDrop] = useState(false);
  const [restrictAddModalOpen, setRestrictAddModalOpen] = useState(false);

  const setRedirectRoute = useCallback(
    (newPathname, redirectUrl = '') => {
      const newRedirectUrl = `${newPathname}${redirectUrl}`;
      history.push(newRedirectUrl);
    },
    [history]
  );

  const setIntroModalsStatus = useCallback(() => {
    const status = {
      getTheLook: false,
      curateTheLook: false,
      curateTheLookTips: false,
      requestTheLook: false,
    };
    localStorage.setItem(
      localStorageKeys.introModalsStatus,
      JSON.stringify(status)
    );
  }, []);

  const handleUpdateCurateSearchParams = useCallback(
    (params) => {
      dispatch(
        updateIISearchParamsAction({
          ...params,
          offset: 0,
        })
      );
    },
    [dispatch]
  );

  const handleUpdateRequestSearchParams = useCallback(
    (params) => {
      dispatch(
        updateRequestIISearchParamsAction({
          ...params,
          offset: 0,
        })
      );
    },
    [dispatch]
  );

  const handleUpdateCurateProductSearchParams = useCallback(
    (params) => {
      dispatch(
        updateProductSearchParamsAction({
          ...params,
          offset: 0,
        })
      );
    },
    [dispatch]
  );

  const setCurateParameters = useCallback(async () => {
    try {
      const lookBoardData = JSON.parse(
        localStorage.getItem(localStorageKeys.lookBoardData)
      );
      const lookBoardFormValues = JSON.parse(
        localStorage.getItem(localStorageKeys.lookBoardDetailsFormValues)
      );
      const editState = JSON.parse(
        localStorage.getItem(localStorageKeys.editState)
      );
      // TODO: pass lookBoard status to restore function
      await dispatch(
        restoreCurateStateAction(lookBoardData, lookBoardFormValues, editState)
      );
      handleUpdateCurateProductSearchParams({ type: productTypeKeys.my });
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [dispatch, handleUpdateCurateProductSearchParams]);

  useEffect(() => {
    const openExtensionChooseMethodModal = queryParams.get('ext');
    const source = imageSourceKeys.my;

    if (openExtensionChooseMethodModal === 'true') {
      localStorageData.current = JSON.parse(localStorage.getItem('parsedData'));
      if (localStorageData.current !== null) {
        const mediaType = localStorageData.current.imageType;
        setCurrentMediaType(mediaType);

        switch (localStorageData.current.pageTitle) {
          case routesByName.dashboard.index:
            if (!authenticated) {
              setRedirectRoute(
                routesByName.home,
                `?${routesByName.auth.key}=${routesByName.auth.signIn}&dashboard=true`
              );
              return;
            }
            setRedirectRoute(routesByName.dashboard.index);
            break;

          case routesByName.curateTheLook.index:
            if (!authenticated) {
              setRedirectRoute(
                routesByName.curateTheLook.canvas,
                `?${routesByName.auth.key}=${routesByName.auth.signIn}&curate=true&skipped=true`
              );
              return;
            }
            handleUpdateCurateSearchParams({ source });
            setRedirectRoute(routesByName.curateTheLook.canvas);
            if (localStorageData.current.imageType === mediaTypes.product) {
              setCurateParameters();
            }
            break;

          case routesByName.requestTheLook.index:
            if (!authenticated) {
              setRedirectRoute(
                routesByName.requestTheLook.index,
                `?${routesByName.auth.key}=${routesByName.auth.signIn}&request=true`
              );
              return;
            }
            handleUpdateRequestSearchParams({ source });
            setRedirectRoute(routesByName.requestTheLook.index);
            break;

          default:
            history.push('');
            errorToastr('Redirecting error');
            break;
        }

        if (!authenticated) {
          setRedirectRoute(
            routesByName.getTheLook.index,
            `?${routesByName.auth.key}=${routesByName.auth.signIn}`
          );
          return;
        }
        if (!hasVerifiedEmail) {
          dispatch(toggleVerificationModalAction(true));
        }
        setSaveImageModalOpenExtension(true);

        if (
          !user?.ambassador &&
          localStorageData.current.imageType === mediaTypes.product &&
          user?.productCount >= appConfig.productCountMax
        ) {
          setRestrictAddModalOpen(true);
        }
      } else {
        history.push('');
      }
    }

    const openExtensionLoginModal = queryParams.get('login');
    if (openExtensionLoginModal === 'true') {
      if (!authenticated) {
        setRedirectRoute(
          routesByName.home,
          `?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      history.push('');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isAmbassadorMatch = queryParams.get('ambassador-match');
    const isRecommendation = queryParams.get('recommendation');
    const requestId = queryParams.get('requestId');

    if (isAmbassadorMatch === 'true' && requestId) {
      if (!authenticated) {
        setRedirectRoute(
          routesByName.home,
          `?${routesByName.auth.key}=${routesByName.auth.signIn}&match=true&requestId=${requestId}`
        );
      }
    }

    if (isRecommendation === 'true' && requestId) {
      if (!authenticated) {
        setRedirectRoute(
          routesByName.home,
          `?${routesByName.auth.key}=${routesByName.auth.signIn}&recommend=true&requestId=${requestId}`
        );
      }
    }
  }, [authenticated, queryParams, setRedirectRoute]);

  useEffect(() => {
    const status = JSON.parse(
      localStorage.getItem(localStorageKeys.introModalsStatus)
    );
    if (!status) {
      setIntroModalsStatus();
    }
  }, [setIntroModalsStatus]);

  useCustomEventListener(
    OPEN_PIN_PRODUCT_MODAL,
    ({ detail: { mediaType } }) => {
      setCurrentMediaType(mediaType);
      setUploadImageModalOpen(true);
    }
  );

  useCustomEventListener(PIN_IMAGE_FOR_REQUEST, () => {
    setRedirectRequired(true);
  });

  const handleAddImageModalClose = useCallback(() => {
    dispatch(toggleAddImageModalAction(false));
  }, [dispatch]);

  const handleChooseMethodModalOpen = useCallback(() => {
    if (!authenticated) {
      const redirectUrl = `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`;
      history.push(redirectUrl);
      return;
    }
    if (!hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    dispatch(toggleChooseMethodModalAction({ isVisible: true, type: 'both' }));
    dispatch(updateCreationImageStatusAction(null));
  }, [pathname, history, authenticated, dispatch, hasVerifiedEmail]);

  const handleChooseMethodModalClose = useCallback(() => {
    dispatch(toggleChooseMethodModalAction({ isVisible: false }));
  }, [dispatch]);

  const handleUploadImageModalOpen = useCallback(
    ({ currentTarget }) => {
      const mediaType = currentTarget?.getAttribute('data-media-type');
      setCurrentMediaType(mediaType);
      setUploadImageModalOpen(true);
      dispatch(toggleChooseMethodModalAction({ isVisible: false }));
    },
    [dispatch]
  );

  const handleUploadImageModalClose = useCallback(() => {
    setUploadImageModalOpen(false);
  }, []);

  const handlePinMediaModalOpen = useCallback(
    ({ currentTarget }) => {
      const mediaType = currentTarget.getAttribute('data-media-type');
      setCurrentMediaType(mediaType);
      dispatch(togglePinMediaModalAction(true));
      dispatch(toggleChooseMethodModalAction({ isVisible: false }));
    },
    [dispatch]
  );

  const handlePinMediaModalCloseExtension = useCallback(() => {
    setSaveImageModalOpenExtension(false);
  }, []);

  const handleCloseRestrictModal = useCallback(() => {
    setRestrictAddModalOpen(false);
  }, []);

  const handleUploadExtensionModalClose = useCallback(() => {
    dispatch(togglePinMediaModalAction(false));
  }, [dispatch]);

  const handleSaveImageModalClose = useCallback(() => {
    setSaveImageModalOpen(false);
    setWithDrop(false);
  }, []);

  const handleAcceptImage = useCallback((imgData) => {
    setNewImage(imgData);
    setUploadImageModalOpen(false);
    setSaveImageModalOpen(true);
  }, []);

  const handleUrlImage = useCallback((imgUrl) => {
    setNewImage(imgUrl.url);
    setNewSiteSourceUrl(imgUrl.siteSourceUrl);
    setUploadImageModalOpen(false);
    setSaveImageModalOpen(true);
  }, []);

  const createInspirationImage = useCallback(
    (mediaId, formValues, reCaptchaToken) => {
      const {
        [createImageFieldKeys.ownership]: ownership,
        [createImageFieldKeys.permissions]: permissions,
      } = formValues;

      const permissionsValue =
        ownership === ownershipValuesMap.thirdPartyOwned
          ? permissionsValuesMap.open
          : permissions;

      const imageData = JSON.parse(localStorage.getItem('parsedData'));

      const createIIPromise = inspirationImageService.createInspirationImage({
        ...formValues,
        url: formValues.sourceUrl || imageData?.imageUrl,
        sourceUrl: formValues.siteSourceUrl || imageData?.url,
        mediaId,
        permissions: permissionsValue,
        reCaptchaToken,
      });
      return createIIPromise;
    },
    []
  );

  const createProduct = useCallback((mediaId, formValues, reCaptchaToken) => {
    const { id, name, itemClassId, description, size, price, url } = formValues;

    return productService.createProduct({
      mediaId,
      id,
      name,
      itemClassId,
      description,
      size,
      price,
      reCaptchaToken,
      url,
    });
  }, []);

  const handleSaveImage = useCallback(
    async ({ ...formValues }) => {
      try {
        const formData = new FormData();

        if (createProductFieldKeys.price in formValues) {
          formData.append('type', mediaTypes.product);
        } else {
          formData.append('type', mediaTypes.inspirationImage);
        }
        formData.append('file', newImage.file);

        const {
          result: { id: mediaId },
        } = await mediaService.uploadMedia(formData);

        let createdImage;
        if (createProductFieldKeys.price in formValues) {
          const preparedFormValues = {
            ...formValues,
            price: formValues.price ? formValues.price * 100 : formValues.price,
          };

          const createdProduct = await createProduct(
            mediaId,
            preparedFormValues,
            formValues.reCaptchaToken
          );

          dispatch(saveInstantlyCreatedProductAction(createdProduct));
        } else {
          createdImage = await createInspirationImage(
            mediaId,
            formValues,
            formValues.reCaptchaToken
          );
          setNewImage(null);

          dispatch(selectImageForFeatureAction(createdImage));
        }

        setWithDrop(false);
        setSaveImageModalOpen(false);
        successToastr('Success', 'New item was created!');
        if (redirectRequired) {
          setRedirectRequired(false);
          history.push(routesByName.requestTheLook.index);
        }
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [
      newImage,
      dispatch,
      redirectRequired,
      createProduct,
      createInspirationImage,
      history,
    ]
  );

  const handleCreateParsedMedia = useCallback(
    async ({ mediaUrl, formValues, reCaptchaToken }) => {
      const { ownership } = formValues;
      try {
        const {
          result: { id: mediaId },
        } = await mediaService.uploadMediaByUrl(currentMediaType, mediaUrl);

        if (currentMediaType === mediaTypes.inspirationImage) {
          const createdImage = await createInspirationImage(
            mediaId,
            formValues,
            reCaptchaToken
          );

          dispatch(selectImageForFeatureAction(createdImage));
        } else {
          const createdProduct = await createProduct(
            mediaId,
            formValues,
            reCaptchaToken
          );
          if (
            localStorageData?.current.pageTitle === routesByName.dashboard.index
          ) {
            dispatch(setDashboardActiveTab(myProductsTabKey));
          }
          dispatch(saveInstantlyCreatedProductAction(createdProduct));
        }

        setParsedProduct(null);
        setWithDrop(false);
        if (saveImageModalOpenExtension) {
          setSaveImageModalOpenExtension(false);
        }
        setSaveImageModalOpen(false);
        dispatch(
          changeIsOwnImageAction(ownership === ownershipValuesMap.selfOwned)
        );
        successToastr('Success', 'New media was created!');
        if (redirectRequired) {
          setRedirectRequired(false);
          history.push(routesByName.requestTheLook.index);
        }
      } catch (e) {
        if (e.responseError.status === 500) {
          errorToastr('Error', e.exceptionMessageError);
        } else {
          errorToastr('Error', e.generalError);
        }
      }
    },
    [
      currentMediaType,
      saveImageModalOpenExtension,
      redirectRequired,
      createInspirationImage,
      dispatch,
      createProduct,
      history,
    ]
  );

  return (
    <>
      <FloatActionsBlock
        onOpenChooseMethodModal={handleChooseMethodModalOpen}
      />
      <AddImageModal
        open={addImageModalOpen}
        onClose={handleAddImageModalClose}
        onImageUpload={handleUploadImageModalOpen}
        onMediaPin={handlePinMediaModalOpen}
      />
      <SaveImageModalExtension
        onClose={handlePinMediaModalCloseExtension}
        initialFormValues={parsedProduct || {}}
        open={saveImageModalOpenExtension}
        mediaType={currentMediaType}
        imgData={localStorageData.current}
        onCreateMedia={handleCreateParsedMedia}
      />
      <ChooseMethodModal
        open={isVisibleChooseMethodModal}
        onClose={handleChooseMethodModalClose}
        onImageUpload={handleUploadImageModalOpen}
        onMediaPin={handlePinMediaModalOpen}
        type={chooseMethodModalType}
      />
      <UploadImageModal
        fullWidth
        setWithDrop={setWithDrop}
        maxWidth="md"
        open={uploadImageModalOpen}
        onClose={handleUploadImageModalClose}
        onAcceptImg={handleAcceptImage}
        onCreateMedia={handleUrlImage}
      />
      <UploadExtensionModal
        fullWidth
        maxWidth="md"
        open={pinMediaModalOpen}
        onClose={handleUploadExtensionModalClose}
      />
      <SaveImageModal
        withDrop={withDrop}
        open={saveImageModalOpen}
        onClose={handleSaveImageModalClose}
        imgUrl={(newImage && newImage.url) || newImage}
        imgSourceUrl={
          (newSiteSourceUrl && newImage.siteSourceUrl) || newSiteSourceUrl
        }
        onSaveImg={handleSaveImage}
        onCreateMedia={handleCreateParsedMedia}
        setNewImage={setNewImage}
        mediaType={currentMediaType}
        changeIsOwnImage={(e) => dispatch(changeIsOwnImageAction(e))}
      />
      <RestrictAddImageModal
        onClose={handleCloseRestrictModal}
        open={restrictAddModalOpen}
        onCloseAddImageModal={handlePinMediaModalCloseExtension}
      />
    </>
  );
};

export default PinMediaContainer;
