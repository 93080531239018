import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import SideBar from 'modules/getTheLook/components/Sidebar';
import { routesByName } from 'constants/routes';
import { getTheLookTabKeys } from 'modules/getTheLook/constants';
import SearchParamsBlock from 'modules/getTheLook/components/SearchParamsBlock';
import LookBoardsHeader from 'modules/getTheLook/components/LookBoardsHeader/LookBoardsHeader';
import TopFilters from 'modules/getTheLook/components/TopFilters/TopFilters';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import { OPEN_REGISTERED_MODAL } from 'constants/customEventNames';
import useCustomEventListener from 'hooks/useCustomEventListener';
import DetailsView from 'modules/getTheLook/DetailsView';
import AmbassadorListView from 'modules/getTheLook/ambassadorPage/AmbassadorListView';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import HowItWorksVideoModal from 'components/modals/HowItWorksVideoModal';
import video from 'assets/video/get-the-look.mp4';
import posterImg from 'assets/video/getTheLookPreview.png';
import classes from './GetTheLook.module.scss';
import MixMatch from './MixMatch';
import ListView from './ListView';
import {
  resetFilterValuesAction,
  updateFilterValuesAction,
} from './store/actions';
import {
  resetAmbassadorFilterValuesAction,
  setAmbassadorsActiveTabAction,
  updateAmbassadorFilterValuesAction,
} from './ambassadorPage/store/actions';
import UsersListView from './userPage/UsersListView';
import UserDetails from './userPage/UserDetails';
import GalleryView from './components/GalleryView';
import SelectedAmbassador from './ambassadorPage/SelectedAmbassador';
import PreferencesRemindModal from './preferencesRemindModal';
import {
  toggleHowItWorksVideoModalAction,
  togglePreferencesRemindModalAction,
} from '../layout/store/actions';
import LookBoardPage from './lookboardPage/LookBoardPage';
import SpriteIcon from '../../components/ui/SpriteIcon';
import { getTheLookAmbassadorsTabKeys } from './ambassadorPage/config';
import { useRegisterEffect } from '../../hooks/useRegisterEffect';

const GetTheLookContainer = () => {
  const dispatch = useDispatch();
  const childFunctionRef = useRef();

  const history = useHistory();
  const selectedImage = useSelector((state) => state.getTheLook.selectedImage);
  const ambassadorsActiveTab = useSelector(
    (state) => state.ambassadorsPage.activeTab
  );
  const { pathname } = useLocation();
  const activeTab = useSelector((state) => state.getTheLook.activeTab);

  const [styleQuizModalOpen, setStyleQuizModalOpen] = useState(false);

  const [isLikedImages, toggleLikedImages] = useState(false);
  const [tmpVideoData, setTmpVideoData] = useState({
    video,
    poster: posterImg,
  });

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const isAmbassadorPage = useMemo(
    () => pathname === routesByName.getTheLook.ambassadors.index,
    [pathname]
  );

  const isUsersPage = useMemo(
    () => pathname.startsWith(routesByName.getTheLook.users.index),
    [pathname]
  );

  useCustomEventListener(OPEN_REGISTERED_MODAL, () => {
    dispatch(togglePreferencesRemindModalAction(true));
  });

  const handleOpenMixMatchModal = useCallback(
    ({ id }) => {
      if (id) {
        history.push(`${routesByName.getTheLook.mixMatch.index}/${id}`);
      } else history.push(routesByName.getTheLook.mixMatch.index);
    },
    [history]
  );

  const handleOpenLookBoardView = useCallback(() => {
    history.push(routesByName.getTheLook.lookBoardView.index);
  }, [history]);

  const handleGTLVideoOpen = useCallback(() => {
    setTmpVideoData({ video, poster: posterImg });
    dispatch(toggleHowItWorksVideoModalAction(true));
  }, [dispatch]);

  const handleApplyStyleQuiz = useCallback(
    (filterValues) => {
      if (filterValues) {
        if (isAmbassadorPage || isUsersPage) {
          dispatch(updateAmbassadorFilterValuesAction(filterValues));
        } else {
          dispatch(updateFilterValuesAction(filterValues));
        }
      } else if (isAmbassadorPage) {
        dispatch(resetAmbassadorFilterValuesAction());
      } else {
        dispatch(resetFilterValuesAction());
      }
      setStyleQuizModalOpen(false);
    },
    [isAmbassadorPage, isUsersPage, dispatch]
  );

  useRegisterEffect();

  // useEffect(() => {
  //   if (!currentUser && !history.location.search) {
  //     history.push(
  //       `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`,
  //       { title: 'REGISTER NOW' }
  //     );
  //   }
  // }, [history, history.location.search, pathname, currentUser]);

  const handleGoBack = useCallback(() => {
    if (ambassadorsActiveTab === getTheLookAmbassadorsTabKeys.lookBoards)
      dispatch(
        setAmbassadorsActiveTabAction(getTheLookAmbassadorsTabKeys.main)
      );
    else history.goBack();
  }, [ambassadorsActiveTab, dispatch, history]);

  const getShowBackButton = useCallback(() => {
    const showcaseRoutes = [
      routesByName.getTheLook.ambassadors.index,
      routesByName.getTheLook.followedAmbassadors.index,
    ];
    return showcaseRoutes.includes(pathname);
  }, [pathname]);

  return (
    <>
      <TopFilters
        handleApplyStyleQuiz={handleApplyStyleQuiz}
        isAmbassadorPage={isAmbassadorPage}
        styleQuizModalOpen={styleQuizModalOpen}
        setStyleQuizModalOpen={setStyleQuizModalOpen}
        setModalVideo={setTmpVideoData}
      />
      <div className={clsx(!matchesMediaQuery && 'flex-fill d-flex')}>
        {matchesMediaQuery && getShowBackButton() && (
          <div
            className={clsx(
              'mt-2 d-flex align-items-center text-xs',
              matchesMediaQuery && 'px-2'
            )}
            onClick={handleGoBack}
          >
            <div className="d-flex justify-content-between align-items-center">
              <SpriteIcon
                name="left-arrow"
                size="sm"
                className="mr-1 primary-color"
              />
              <span>Back</span>
            </div>
          </div>
        )}
        {!matchesMediaQuery && <SideBar />}
        <div className="flex-fill d-flex flex-column position-relative p-2">
          <div
            className={clsx(
              classes.leftPanelBg,
              pathname === routesByName.getTheLook.index && classes.gtlMainPage
            )}
          />
          <div className="d-flex">
            {!matchesMediaQuery && (
              <>
                <div className={clsx(classes.leftPanelWrapper, `pt-3`)}>
                  <SearchParamsBlock childFunctionRef={childFunctionRef} />
                </div>
                <div className="flex-fill">
                  <div className={` d-flex flex-column px-3`}>
                    <LookBoardsHeader
                      onOpenMixMatchModal={handleOpenMixMatchModal}
                      onOpenLookBoardViewPage={handleOpenLookBoardView}
                      toggleLikedImages={toggleLikedImages}
                      onModalOpen={handleGTLVideoOpen}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <Switch>
            <Route exact path={routesByName.getTheLook.index}>
              {matchesMediaQuery && (
                <div className={clsx(classes.leftPanelWrapper, `pt-3`)}>
                  <SearchParamsBlock />
                </div>
              )}
              {activeTab === getTheLookTabKeys.galleryView && !selectedImage ? (
                <GalleryView
                  setStyleQuizModalOpen={setStyleQuizModalOpen}
                  showLikedImages={isLikedImages}
                  childFunctionRef={childFunctionRef}
                />
              ) : (
                <ListView setStyleQuizModalOpen={setStyleQuizModalOpen} />
              )}
            </Route>
            <Route exact path={routesByName.getTheLook.ambassadors.index}>
              {matchesMediaQuery && (
                <div className={clsx(classes.leftPanelWrapper, `pt-3`)}>
                  <SearchParamsBlock />
                </div>
              )}
              <AmbassadorListView />
            </Route>
            <Route
              exact
              path={routesByName.getTheLook.followedAmbassadors.index}
            >
              {matchesMediaQuery && (
                <div className={clsx(classes.leftPanelWrapper, `pt-3`)}>
                  <SearchParamsBlock />
                </div>
              )}
              <AmbassadorListView isFollowed />
            </Route>
            <Route exact path={routesByName.getTheLook.mixMatch.index}>
              <MixMatch />
            </Route>
            <Route exact path={routesByName.getTheLook.lookBoardView.index}>
              {matchesMediaQuery && (
                <div className={clsx(classes.leftPanelWrapper, `pt-3`)}>
                  <SearchParamsBlock />
                </div>
              )}
              {activeTab === getTheLookTabKeys.galleryView && !selectedImage ? (
                <GalleryView setStyleQuizModalOpen={setStyleQuizModalOpen} />
              ) : (
                <ListView setStyleQuizModalOpen={setStyleQuizModalOpen} />
              )}
            </Route>
            <Route exact path={routesByName.getTheLook.users.index}>
              <UsersListView />
            </Route>
            <Route exact path={routesByName.getTheLook.mixMatch.index}>
              <MixMatch />
            </Route>
            <Route
              exact
              path={routesByName.getTheLook.mixMatch.lookBoards.index}
            >
              <MixMatch isLookBoards />
            </Route>
            <Route
              exact
              path={routesByName.getTheLook.mixMatch.details()}
              render={(props) => <DetailsView {...props} />}
            />
            <Route
              exact
              path={routesByName.getTheLook.mixMatch.lookBoards.details()}
              render={(props) => <DetailsView isLookBoard {...props} />}
            />
            <Route
              exact
              path={routesByName.getTheLook.lookBoard.details()}
              render={(props) => <LookBoardPage {...props} />}
            />
            <Route
              exact
              path={routesByName.getTheLook.users.details()}
              render={(props) => <UserDetails {...props} />}
            />
            <Route
              exact
              path={routesByName.getTheLook.ambassadors.details()}
              render={(props) => <SelectedAmbassador {...props} />}
            />
            <Route
              exact
              path={routesByName.getTheLook.details()}
              render={(props) => <DetailsView {...props} />}
            />
          </Switch>
        </div>
      </div>

      <PreferencesRemindModal />
      <HowItWorksVideoModal
        video={tmpVideoData.video}
        posterImg={tmpVideoData.poster}
      />
    </>
  );
};

export default GetTheLookContainer;
