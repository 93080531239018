import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  handleDragEndAction,
  updateCurateStateAction,
} from 'modules/curateTheLook/store/actions';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import HowItWorksVideoModal from 'components/modals/HowItWorksVideoModal';
import video from 'assets/video/curateTheLook.mp4';
import posterImg from 'assets/video/curateTheLookPreview.png';
import LeftPanel from 'modules/curateTheLook/createLookBoard/LeftPanel';
import RightPanel from 'modules/curateTheLook/createLookBoard/RightPanel';
import MainArea from 'modules/curateTheLook/createLookBoard/MainArea';
import ImgDragModal from './components/ImgPreviewModal/ImgDragModal';
import LookBoardDragModal from './components/LookBoardDragModal';
import ForceRegisterModal from "../../../components/modals/ForceRegisterModal";

const CreateLookBoardContainer = ({ onRequestDetailsPreview }) => {
  const dispatch = useDispatch();
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);
  const [imgDragModalOpen, setImgDragModalOpen] = useState(false);
  const [lookBoardDragModalOpen, setLookBoardDragModalOpen] = useState(false);

  const [imageProductSize, setImageProductSize] = useState(0);
  const [currentLookBoard, setCurrentLookBoard] = useState();

  const handleImgModalOpen = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleImgDragModalClose = useCallback(() => {
    setImgDragModalOpen(false);
  }, []);

  const handleImgDragModalOpen = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgDragModalOpen(true);
  }, []);

  const handleLookBoardDragModalClose = useCallback(() => {
    setLookBoardDragModalOpen(false);
  }, []);

  const handleLookBoardDragModalOpen = useCallback(() => {
    setLookBoardDragModalOpen(true);
  }, []);

  // next two methods resizing product for correct drag'n'drop grid layout
  const onBeforeCapture = useCallback(() => {
    setImageProductSize((prevSize) => prevSize / 2);
  }, []);

  const onBeforeDragStart = useCallback(() => {
    setImageProductSize((prevSize) => prevSize * 2);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        updateCurateStateAction({
          requestInfo: null,
        })
      );
    };
  }, [dispatch]);

  return (
    <div className="flex-fill d-flex">
      <LeftPanel
        onOpenPreview={handleImgModalOpen}
        onOpenDragModal={handleImgDragModalOpen}
        onRequestDetailsPreview={onRequestDetailsPreview}
        setCurrentLookBoardExtend={setCurrentLookBoard}
      />
      <DragDropContext
        onDragEnd={(e) => dispatch(handleDragEndAction(e))}
        onBeforeDragStart={onBeforeDragStart}
        onBeforeCapture={onBeforeCapture}
      >
        <MainArea
          onOpenPreview={handleImgDragModalOpen}
          imageProductSize={imageProductSize}
          setImageProductSize={setImageProductSize}
          currentLookBoard={currentLookBoard}
          onOpenLookBoardDragModal={handleLookBoardDragModalOpen}
        />
        <RightPanel />
      </DragDropContext>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
      <ImgDragModal
        open={imgDragModalOpen}
        onClose={handleImgDragModalClose}
        url={imgPreviewUrl}
      />
      <LookBoardDragModal
        onClose={handleLookBoardDragModalClose}
        open={lookBoardDragModalOpen}
        lookBoardData={currentLookBoard}
      />
      <HowItWorksVideoModal video={video} posterImg={posterImg} />
      <ForceRegisterModal type="CTL" />
    </div>
  );
};

CreateLookBoardContainer.propTypes = {
  onRequestDetailsPreview: PropTypes.func.isRequired,
};

export default CreateLookBoardContainer;
