import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SpriteIcon from 'components/ui/SpriteIcon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio } from '@material-ui/core';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';
import {
  ambassadorImageSearchRadioOptions,
  ambassadorSearchNonLoginOptions,
  userTypes,
} from 'modules/curateTheLook/constants';
import {
  clearAllFilterValuesAction,
  resetFilterValuesAction,
  updateFilterValuesAction,
  updateSearchParamsAction,
} from 'modules/getTheLook/store/actions';
import { routesByName } from 'constants/routes';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import {
  ADD_PREFERENCES,
  GTL_OPEN_FILTERS_MODAL,
} from 'constants/customEventNames';
import findObjectById from 'utils/findObjectById';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';
import FiltersModal from 'components/modals/FiltersModal/FiltersModal';
import useCustomEventListener from 'hooks/useCustomEventListener';
import {
  clearAllAmbassadorFilterValuesAction,
  resetAmbassadorFilterValuesAction,
  setAmbassadorsActiveTabAction,
  updateAmbassadorsFilterValuesAction,
  updateAmbassadorsSearchParamsAction,
} from 'modules/getTheLook/ambassadorPage/store/actions';
import classes from 'modules/curateTheLook/createLookBoard/components/ImagesSearchParamsBlock/ImagesSearchParamsBlock.module.scss';
import Button from 'components/ui/Button/Button';
import ConfirmModal from 'components/modals/ConfirmModal';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import IconButton from 'components/ui/IconButton/IconButton';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import {
  ambassadorFilterTabKeys,
  ambassadorFilterTabsConfig,
  ambassadorsFilterModalTitle,
  getTheLookAmbassadorsTabKeys,
  professionOptions,
} from '../ambassadorPage/config';
import { updateUserSearchAction } from '../userPage/store/actions';
import { infoMenuConfig } from '../constants';
import InfoMenu from '../../layout/SideNav/InfoMenu/InfoMenu';
import customEvent from '../../../utils/customEvent';

// eslint-disable-next-line
const SearchParamsBlock = ({ childFunctionRef }) => {
  const {
    searchParams: getTheLookSearchParams,
    filterValues: filterImagesValues,
  } = useSelector((state) => state.getTheLook);
  const {
    ambassadorsSearchParams,
    ambassadorsFilterValues,
    activeTab,
  } = useSelector((state) => state.ambassadorsPage);
  const searchParams = useMemo(
    () => getTheLookSearchParams || ambassadorsSearchParams,
    [getTheLookSearchParams, ambassadorsSearchParams]
  );

  const currentUser = useSelector((state) => state.auth.user);
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const dispatch = useDispatch();

  const history = useHistory();
  const { pathname } = useLocation();
  const inputRef = useRef(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [clearAllModalOpen, setClearAllModalOpen] = useState(false);
  const [activeRadioButton, setActiveRadioButton] = useState(
    userTypes.ambassador
  );
  const regularUserSearch = useSelector(
    (state) => state.userPage.usersSearchParams.search
  );
  const styles = useSelector((state) => state.getTheLook.filterValues.styles);
  const stylesConfig = useSelector((state) => state.app.enums.styles);

  const ambassadorsFilterValuesCopy = useMemo(() => {
    // eslint-disable-next-line react/prop-types
    if (typeof ambassadorsFilterValues.profession === 'string') {
      const professionOption = professionOptions.find(
        // eslint-disable-next-line react/prop-types
        (option) => option.value === ambassadorsFilterValues.profession
      );
      const professionToArray = professionOption ? [professionOption.id] : [];

      return {
        ...ambassadorsFilterValues,
        profession: professionToArray,
      };
    }
    return {
      ...ambassadorsFilterValues,
    };
  }, [ambassadorsFilterValues]);

  const showControls = useMemo(
    () =>
      pathname === routesByName.getTheLook.index ||
      pathname === routesByName.getTheLook.mixMatch.index ||
      pathname === routesByName.getTheLook.ambassadors.index ||
      pathname === routesByName.getTheLook.users.index ||
      pathname === routesByName.getTheLook.lookBoardView.index ||
      pathname === routesByName.getTheLook.followedAmbassadors.index ||
      pathname === routesByName.getTheLook.mixMatch.lookBoards.index,
    [pathname]
  );
  const isAmbassadorPage = useMemo(
    () =>
      pathname === routesByName.getTheLook.ambassadors.index ||
      pathname === routesByName.getTheLook.followedAmbassadors.index,
    [pathname]
  );

  const isUsersPage = useMemo(
    () => pathname === routesByName.getTheLook.users.index,
    [pathname]
  );

  const isUserLookboardsPage = useMemo(
    () => pathname.startsWith(routesByName.getTheLook.users.index),
    [pathname]
  );

  const inputValue = useMemo(() => {
    if (isAmbassadorPage) return ambassadorsSearchParams.search;
    if (isUsersPage) return regularUserSearch;
    return searchParams.search;
  }, [
    ambassadorsSearchParams.search,
    isAmbassadorPage,
    isUsersPage,
    regularUserSearch,
    searchParams.search,
  ]);

  const isFiltersHidden = useMemo(
    () =>
      activeTab === getTheLookAmbassadorsTabKeys.showcase ||
      activeTab === getTheLookAmbassadorsTabKeys.lookBoards,
    [activeTab]
  );

  useCustomEventListener(GTL_OPEN_FILTERS_MODAL, () => {
    setFiltersModalOpen(true);
  });

  const handleOpenResetModal = useCallback(() => {
    setResetModalOpen(true);
  }, []);

  const handleUpdateSearchParams = useCallback(
    (params) => {
      if (isAmbassadorPage) {
        dispatch(updateAmbassadorsSearchParamsAction({ ...params, offset: 0 }));
      } else if (isUsersPage) {
        dispatch(updateUserSearchAction({ ...params, offset: 0 }));
      } else {
        dispatch(updateSearchParamsAction({ ...params, offset: 0 }));
      }
    },
    [dispatch, isAmbassadorPage, isUsersPage]
  );
  const handleCrossClick = useCallback(() => {
    handleUpdateSearchParams({ search: '' });
  }, [handleUpdateSearchParams]);

  const handleResetFilters = useCallback(
    (confirm) => {
      if (confirm) {
        handleUpdateSearchParams({ search: '' });
        if (isAmbassadorPage) {
          dispatch(resetAmbassadorFilterValuesAction());
        } else {
          dispatch(resetFilterValuesAction());
        }
      }
      setResetModalOpen(false);
    },
    [handleUpdateSearchParams, isAmbassadorPage, dispatch]
  );

  const handleOpenClearAllModal = useCallback(() => {
    setClearAllModalOpen(true);
  }, []);

  const handleClearAllFilters = useCallback(
    (confirm) => {
      if (confirm) {
        handleUpdateSearchParams({ search: '' });
        if (isAmbassadorPage) {
          dispatch(clearAllAmbassadorFilterValuesAction());
        } else {
          // eslint-disable-next-line no-unused-expressions
          dispatch(
            clearAllFilterValuesAction(currentUser ? 'liked' : undefined)
          );
        }
      }
      setClearAllModalOpen(false);
    },
    [currentUser, dispatch, handleUpdateSearchParams, isAmbassadorPage]
  );

  const handleOpenFiltersModal = useCallback(() => {
    /* if (!currentUser) {
      history.push(`?${routesByName.auth.key}=${routesByName.auth.signUp}`);
      return;
    } */
    setFiltersModalOpen(true);
  }, []);

  const handleCloseFiltersModal = useCallback(() => {
    setFiltersModalOpen(false);
  }, []);

  const handleApplyFilters = useCallback(
    (filters) => {
      if (isAmbassadorPage || isUsersPage) {
        dispatch(updateAmbassadorsFilterValuesAction(filters));
      } else {
        dispatch(updateFilterValuesAction(filters));
      }
    },
    [isAmbassadorPage, isUsersPage, dispatch]
  );

  const handleChangeUserType = useCallback(
    ({ target: { value: type } }) => {
      setActiveRadioButton(type);
      history.push(
        type === userTypes.ambassador
          ? routesByName.getTheLook.ambassadors.index
          : routesByName.getTheLook.followedAmbassadors.index
      );
    },
    [history]
  );

  useEffect(() => {
    if (childFunctionRef) {
      // eslint-disable-next-line
      childFunctionRef.current = handleOpenFiltersModal;
    }
    // eslint-disable-next-line
  }, [childFunctionRef]);

  useEffect(() => {
    if (
      !currentUser &&
      pathname === routesByName.getTheLook.followedAmbassadors.index
    ) {
      history.push(routesByName.getTheLook.ambassadors.index);
      setActiveRadioButton(userTypes.ambassador);
      return;
    }
    if (pathname === routesByName.getTheLook.followedAmbassadors.index) {
      setActiveRadioButton(userTypes.followedAmbassadors);
    } else {
      setActiveRadioButton(userTypes.ambassador);
    }
    // eslint-disable-next-line
  }, [pathname, currentUser]);

  const handleClearAll = useCallback(() => {
    if (isAmbassadorPage || isUsersPage) {
      dispatch(clearAllAmbassadorFilterValuesAction());
    } else {
      dispatch(clearAllFilterValuesAction());
    }
  }, [dispatch, isAmbassadorPage, isUsersPage]);

  const handleGoToPreferences = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    history.push(routesByName.account.profile);
    setTimeout(() => {
      customEvent.trigger(ADD_PREFERENCES);
    }, 0);
  }, [pathname, currentUser, history]);

  const label = useMemo(() => {
    if (matchesMediaQuery) return 'Scroll Down to Select an Image';
    if (isAmbassadorPage) return 'Ambassador User Showcase Pages';
    if (isUsersPage) return 'Regular User LookBoards';
    if (isUserLookboardsPage) return 'Look boards';
    return 'Inspiration Images';
  }, [isAmbassadorPage, isUserLookboardsPage, isUsersPage, matchesMediaQuery]);

  const handleChangeSearchQuery = useCallback(
    (search) => {
      handleUpdateSearchParams({ search });
    },
    [handleUpdateSearchParams]
  );

  const getSearchTitle = useCallback(() => {
    switch (pathname) {
      case routesByName.getTheLook.index:
        return 'Get The Look';
      case routesByName.getTheLook.ambassadors.index:
      case routesByName.getTheLook.followedAmbassadors.index:
        return 'Showcases';
      default:
        return 'Get The Look';
    }
  }, [pathname]);

  const handleToggle = useCallback(
    (urls) => {
      const route = urls[0];
      if (route) history.push(route);
      dispatch(
        setAmbassadorsActiveTabAction(getTheLookAmbassadorsTabKeys.main)
      );
    },
    [dispatch, history]
  );

  const handleShowInfo = useCallback(() => setInfoModalOpen(true), []);
  const handleHideInfo = useCallback(() => setInfoModalOpen(false), []);

  const subTitle = useMemo(
    () => ({
      label:
        searchParams.type === imageTypeKeys.room
          ? 'Room Images'
          : 'Single Items',
      description:
        searchParams.type === imageTypeKeys.room
          ? 'Room Design'
          : 'Single Item',
    }),
    [searchParams.type]
  );

  const infoMenuModalConfig = useMemo(() => {
    return { ...infoMenuConfig, items: [infoMenuConfig.items[0]] };
  }, []);

  const radioButtons = [
    {
      label: 'Curated Looks',
      urls: [routesByName.getTheLook.index],
    },
    {
      label: 'Showcases',
      urls: [
        routesByName.getTheLook.ambassadors.index,
        routesByName.getTheLook.followedAmbassadors.index,
      ],
    },
  ];

  return (
    <>
      {matchesMediaQuery && (
        <div className={classes.infoWrapper}>
          <InfoMenu
            config={infoMenuModalConfig}
            isOpen={infoModalOpen}
            onClose={handleHideInfo}
            mobile
          />
        </div>
      )}
      <div className="position-relative d-flex mb-2 justify-content-between align-items-center">
        <div className={classes.filtersBlock}>
          <h3 className="text-lg font-semi-bold mb-1">{getSearchTitle()}</h3>
          {matchesMediaQuery && pathname === routesByName.getTheLook.index && (
            <div>
              {radioButtons.map((radioBtn) => (
                <FormControlLabel
                  control={
                    <Radio
                      checked={radioBtn.urls.includes(pathname)}
                      onClick={() => handleToggle(radioBtn.urls)}
                      color="secondary"
                    />
                  }
                  label={<div className="fw-500 text-sm">{radioBtn.label}</div>}
                  key={radioBtn.label}
                />
              ))}
            </div>
          )}
          <p className="text-xs font-italic mt-1">{label}</p>
        </div>
        {matchesMediaQuery && (
          <SpriteIcon
            name="info"
            color="#c4c4c4"
            size="lg"
            onClick={handleShowInfo}
          />
        )}
      </div>
      {!isFiltersHidden && showControls && (
        <>
          {matchesMediaQuery ? (
            <>
              <div className="d-flex mb-2">
                <div className="flex-fill">
                  <SearchInput
                    inputRef={inputRef}
                    value={inputValue}
                    placeholder="Search by Name or Style"
                    onChange={handleChangeSearchQuery}
                    onCrossClick={handleCrossClick}
                  />
                </div>

                <IconButton
                  variant="inverted-white"
                  onClick={handleOpenFiltersModal}
                  className="ml-1"
                >
                  <SpriteIcon name="filters" size="sm" />
                </IconButton>
              </div>
              <div className={classes.chosenStyles}>
                <div className="d-flex align-items-center mb-1">
                  <h3 className="m-0">Selected styles({styles.length})</h3>
                  {!currentUser?.preferences && (
                    <Button
                      size="sm"
                      className="w-auto ml-2"
                      onClick={handleGoToPreferences}
                    >
                      Set Preferences
                    </Button>
                  )}
                </div>
                <div className={classes.stylesContainer}>
                  {styles.map((styleId) => (
                    <div key={styleId} className={classes.styleItem}>
                      {findObjectById(styleId, stylesConfig).name}
                    </div>
                  ))}
                </div>
              </div>
              <p className="font-semi-bold">
                {isAmbassadorPage ? 'Ambassador Showcases' : subTitle.label}
              </p>
            </>
          ) : (
            <>
              <div className={classes.filtersBtn}>View or Change Filters</div>
              <div className={classes.resetArea}>
                <Button
                  inline
                  variant="outlined"
                  onClick={handleOpenFiltersModal}
                  className="ml-1"
                  size="sm"
                >
                  <SpriteIcon name="filters" size="sm" className="mr-1" />
                  <span>Filters</span>
                </Button>
                <Button
                  inline
                  variant="default"
                  size="xs"
                  className="d-flex align-items-center"
                  onClick={handleOpenResetModal}
                >
                  <SpriteIcon name="processing" size="sm" className="mr-1" />
                  <span>Reset</span>
                </Button>
                <div className={classes.decorLine} />
                <Button
                  variant="default"
                  size="custom"
                  onClick={handleOpenClearAllModal}
                >
                  <div className={classes.spriteBg}>
                    <SpriteIcon name="trash" size="sm" className="mr-1" />
                  </div>
                  <span>Clear All</span>
                </Button>
              </div>
            </>
          )}
        </>
      )}
      {((isAmbassadorPage && activeTab === getTheLookAmbassadorsTabKeys.main) ||
        isUsersPage) && (
        <div className="mt-2">
          <CustomRadioGroup
            controls={
              currentUser
                ? ambassadorImageSearchRadioOptions
                : ambassadorSearchNonLoginOptions
            }
            value={activeRadioButton}
            onChange={handleChangeUserType}
            disableMb
          />
        </div>
      )}
      {isAmbassadorPage && !matchesMediaQuery && (
        <SearchInput
          inputRef={inputRef}
          value={inputValue}
          placeholder="Search by Name or Style"
          onChange={handleChangeSearchQuery}
          onCrossClick={handleCrossClick}
        />
      )}
      {/*
      !isAmbassadorPage && !isUsersPage && (
        <div className="mt-2 text-capitalize">
          <p className="font-semi-bold mb-1">{subTitle.label}</p>
          <p className="text-xs font-italic">
            Get the look of a {subTitle.description}
          </p>
        </div>
      ) */}

      <ConfirmModal
        open={resetModalOpen}
        onClose={handleResetFilters}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">reset</span> filters to default
            {currentUser?.preferences && (
              <span>
                <br />
                preferences
              </span>
            )}
            ?
          </>
        }
      />
      <ConfirmModal
        onClose={handleClearAllFilters}
        open={clearAllModalOpen}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">clear</span> all filters?
          </>
        }
      />
      <FiltersModal
        showQuickFilter
        disabledFilters={[
          filterTabKeys.vibes,
          filterTabKeys.itemClasses,
          filterTabKeys.videos,
          filterTabKeys.metaTags,
        ]}
        open={filtersModalOpen}
        onClose={handleCloseFiltersModal}
        imageType={searchParams.type}
        filterValues={
          isAmbassadorPage || isUsersPage
            ? ambassadorsFilterValuesCopy
            : filterImagesValues
        }
        onReset={
          isAmbassadorPage || isUsersPage
            ? () => dispatch(resetAmbassadorFilterValuesAction())
            : () => dispatch(resetFilterValuesAction())
        }
        title={isAmbassadorPage ? ambassadorsFilterModalTitle.title : undefined}
        onApply={handleApplyFilters}
        onClearAll={handleClearAll}
        isAmbassadorsFilter={isAmbassadorPage || isUsersPage}
        customTabsConfig={
          isAmbassadorPage || isUsersPage ? ambassadorFilterTabsConfig : null
        }
        customTabKeys={
          isAmbassadorPage || isUsersPage ? ambassadorFilterTabKeys : null
        }
        handleUpdateSearchParams={handleUpdateSearchParams}
      />
    </>
  );
};

export default SearchParamsBlock;
